/*
*
* ASTRONAUT WEB APP
*
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
/* Template */
/*
*
* ASTRONAUT PUBLIC WEB APP
* TYPOGRAPHY
*
*/
@import url("https://use.fontawesome.com/releases/v5.0.13/css/all.css");
@import url("https://use.fontawesome.com/releases/v5.0.13/css/all.css");
.heading1, .heading2, .card .card-title, .heading3, .heading4, .card .card-text, .heading5, .heading6 {
  margin: 0; }

.heading1 {
  font-size: 32px;
  line-height: 52px;
  font-weight: bold; }
  .heading1.larger {
    font-size: 42px;
    line-height: 52px;
    font-weight: bold; }

.heading2, .card .card-title {
  font-size: 24px;
  line-height: 38px;
  font-weight: bold; }

.heading3 {
  font-size: 22px;
  line-height: 36px;
  font-weight: bold; }

.heading4, .card .card-text {
  font-size: 18px;
  line-height: 32px;
  font-weight: normal; }

.heading5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600; }

.heading6 {
  font-size: 14px;
  line-height: 16px;
  font-weight: normal; }

p {
  font-size: 12px;
  line-height: 18px;
  font-weight: normal; }

.bold {
  font-weight: bold; }

.bold-important {
  font-weight: bold; }
  .bold-important span {
    font-weight: bold; }

.semibold {
  font-weight: 600; }

.normal {
  font-weight: normal; }

.small-text {
  font-size: 14px;
  font-weight: normal; }

.uppercase {
  text-transform: uppercase; }

.capitalize {
  text-transform: capitalize; }

p {
  margin-bottom: 0; }

.underline {
  text-decoration: underline; }

.heading-ftq {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px; }

.button-impress {
  background-color: #395723;
  color: white;
  margin: auto; }
  .button-impress:hover, .button-impress:focus, .button-impress:active {
    background-color: #395723; }

.p-test-mic {
  color: #666666;
  font-size: 12px; }

.course-reset-text {
  font-size: 20px;
  line-height: 24px;
  color: #707070; }

.a-action {
  color: #554AE4;
  font-weight: bold;
  cursor: pointer; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* BASE
*
*/
/*
*
* ASTRONAUT PUBLIC WEB APP
* VARIABLES
*
*/
.text-white {
  color: #FFFFFF; }

.text-purple {
  color: #564FDB; }

.text-link {
  color: #132ED5; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* MIXINS
*
*/
html, body {
  width: 100%;
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.57142857;
  background: #F2F2F2;
  color: #333333; }

a {
  cursor: pointer;
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: none; }

button {
  border: 0; }

button, button:focus, .btn, .btn:focus {
  outline: none !important;
  outline: 0;
  cursor: pointer; }

.hide {
  display: none !important; }

.hidden {
  display: none; }

.no-margin {
  margin: 0; }

.no-rounded-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fixed {
  position: fixed; }

/*
* FLEX CONTAINER
*/
.flex-container, .vertical-item--center, .panel, .vertical-item--top, .vertical-item--justify, .horizontal-item--around, .horizontal-item--between, .horizontal-item--evenly, .horizontal-item--center, .indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-between, .horizontal-item--between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.flex-evenly, .horizontal-item--evenly {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-evenly; }

.flex-start, .vertical-item--justify {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-center, .horizontal-item--center, .card__note, .card--loading {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.align-items-center, .vertical-item--center, .panel, .horizontal-item--around, .horizontal-item--between, .horizontal-item--evenly, .horizontal-item--center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.align-items-start, .vertical-item--top {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start; }

.align-items-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end; }

.flex-row {
  flex-direction: row; }

.flex-grow {
  flex-grow: 1;
  flex: 1; }

.flex-column {
  flex-direction: column; }

.navbar {
  background: #FFFFFF;
  border-bottom: 1px solid #3E3C82; }

.navbar-header {
  display: block;
  opacity: 1;
  padding: 21px 0; }
  .navbar-header img {
    text-align: left;
    margin: 0;
    max-height: 37px; }

.block {
  display: block; }

.center {
  margin: 0 auto;
  text-align: center; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.valign-middle {
  vertical-align: middle; }

.valign-bottom {
  vertical-align: bottom; }

.valign-top {
  vertical-align: top; }

.max-w-100 {
  max-width: 100%; }

.w-100 {
  width: 100%; }

.float-right {
  float: right; }

.color-666 {
  color: #666666; }

.color-999 {
  color: #999999; }

/*
* MARGINS & PADDINGS
*/
.p-0 {
  padding: 0px; }

.p-t-0 {
  padding-top: 0px; }

.p-l-0 {
  padding-left: 0px; }

.p-r-0 {
  padding-right: 0px; }

.p-b-0 {
  padding-bottom: 0px; }

.m-0 {
  margin: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-l-n-0 {
  margin-left: -0px; }

.m-t-0 {
  margin-top: 0px; }

.m-t-n-0 {
  margin-top: -0px; }

.m-r-0 {
  margin-right: 0px; }

.m-r-n-0 {
  margin-right: -0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-b-n-0 {
  margin-bottom: -0px; }

.p-4 {
  padding: 4px; }

.p-t-4 {
  padding-top: 4px; }

.p-l-4 {
  padding-left: 4px; }

.p-r-4 {
  padding-right: 4px; }

.p-b-4 {
  padding-bottom: 4px; }

.m-4 {
  margin: 4px; }

.m-l-4 {
  margin-left: 4px; }

.m-l-n-4 {
  margin-left: -4px; }

.m-t-4 {
  margin-top: 4px; }

.m-t-n-4 {
  margin-top: -4px; }

.m-r-4 {
  margin-right: 4px; }

.m-r-n-4 {
  margin-right: -4px; }

.m-b-4 {
  margin-bottom: 4px; }

.m-b-n-4 {
  margin-bottom: -4px; }

.p-5 {
  padding: 5px; }

.p-t-5 {
  padding-top: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.m-5 {
  margin: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-l-n-5 {
  margin-left: -5px; }

.m-t-5 {
  margin-top: 5px; }

.m-t-n-5 {
  margin-top: -5px; }

.m-r-5 {
  margin-right: 5px; }

.m-r-n-5 {
  margin-right: -5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-b-n-5 {
  margin-bottom: -5px; }

.p-6 {
  padding: 6px; }

.p-t-6 {
  padding-top: 6px; }

.p-l-6 {
  padding-left: 6px; }

.p-r-6 {
  padding-right: 6px; }

.p-b-6 {
  padding-bottom: 6px; }

.m-6 {
  margin: 6px; }

.m-l-6 {
  margin-left: 6px; }

.m-l-n-6 {
  margin-left: -6px; }

.m-t-6 {
  margin-top: 6px; }

.m-t-n-6 {
  margin-top: -6px; }

.m-r-6 {
  margin-right: 6px; }

.m-r-n-6 {
  margin-right: -6px; }

.m-b-6 {
  margin-bottom: 6px; }

.m-b-n-6 {
  margin-bottom: -6px; }

.p-8 {
  padding: 8px; }

.p-t-8 {
  padding-top: 8px; }

.p-l-8 {
  padding-left: 8px; }

.p-r-8 {
  padding-right: 8px; }

.p-b-8 {
  padding-bottom: 8px; }

.m-8 {
  margin: 8px; }

.m-l-8 {
  margin-left: 8px; }

.m-l-n-8 {
  margin-left: -8px; }

.m-t-8 {
  margin-top: 8px; }

.m-t-n-8 {
  margin-top: -8px; }

.m-r-8 {
  margin-right: 8px; }

.m-r-n-8 {
  margin-right: -8px; }

.m-b-8 {
  margin-bottom: 8px; }

.m-b-n-8 {
  margin-bottom: -8px; }

.p-10 {
  padding: 10px; }

.p-t-10 {
  padding-top: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.m-10 {
  margin: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-l-n-10 {
  margin-left: -10px; }

.m-t-10 {
  margin-top: 10px; }

.m-t-n-10 {
  margin-top: -10px; }

.m-r-10 {
  margin-right: 10px; }

.m-r-n-10 {
  margin-right: -10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-b-n-10 {
  margin-bottom: -10px; }

.p-12 {
  padding: 12px; }

.p-t-12 {
  padding-top: 12px; }

.p-l-12 {
  padding-left: 12px; }

.p-r-12 {
  padding-right: 12px; }

.p-b-12 {
  padding-bottom: 12px; }

.m-12 {
  margin: 12px; }

.m-l-12 {
  margin-left: 12px; }

.m-l-n-12 {
  margin-left: -12px; }

.m-t-12 {
  margin-top: 12px; }

.m-t-n-12 {
  margin-top: -12px; }

.m-r-12 {
  margin-right: 12px; }

.m-r-n-12 {
  margin-right: -12px; }

.m-b-12 {
  margin-bottom: 12px; }

.m-b-n-12 {
  margin-bottom: -12px; }

.p-15 {
  padding: 15px; }

.p-t-15 {
  padding-top: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.m-15 {
  margin: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-l-n-15 {
  margin-left: -15px; }

.m-t-15 {
  margin-top: 15px; }

.m-t-n-15 {
  margin-top: -15px; }

.m-r-15 {
  margin-right: 15px; }

.m-r-n-15 {
  margin-right: -15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-b-n-15 {
  margin-bottom: -15px; }

.p-16 {
  padding: 16px; }

.p-t-16 {
  padding-top: 16px; }

.p-l-16 {
  padding-left: 16px; }

.p-r-16 {
  padding-right: 16px; }

.p-b-16 {
  padding-bottom: 16px; }

.m-16 {
  margin: 16px; }

.m-l-16 {
  margin-left: 16px; }

.m-l-n-16 {
  margin-left: -16px; }

.m-t-16 {
  margin-top: 16px; }

.m-t-n-16 {
  margin-top: -16px; }

.m-r-16 {
  margin-right: 16px; }

.m-r-n-16 {
  margin-right: -16px; }

.m-b-16 {
  margin-bottom: 16px; }

.m-b-n-16 {
  margin-bottom: -16px; }

.p-20 {
  padding: 20px; }

.p-t-20 {
  padding-top: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.m-20 {
  margin: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-l-n-20 {
  margin-left: -20px; }

.m-t-20 {
  margin-top: 20px; }

.m-t-n-20 {
  margin-top: -20px; }

.m-r-20 {
  margin-right: 20px; }

.m-r-n-20 {
  margin-right: -20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-b-n-20 {
  margin-bottom: -20px; }

.p-22 {
  padding: 22px; }

.p-t-22 {
  padding-top: 22px; }

.p-l-22 {
  padding-left: 22px; }

.p-r-22 {
  padding-right: 22px; }

.p-b-22 {
  padding-bottom: 22px; }

.m-22 {
  margin: 22px; }

.m-l-22 {
  margin-left: 22px; }

.m-l-n-22 {
  margin-left: -22px; }

.m-t-22 {
  margin-top: 22px; }

.m-t-n-22 {
  margin-top: -22px; }

.m-r-22 {
  margin-right: 22px; }

.m-r-n-22 {
  margin-right: -22px; }

.m-b-22 {
  margin-bottom: 22px; }

.m-b-n-22 {
  margin-bottom: -22px; }

.p-24 {
  padding: 24px; }

.p-t-24 {
  padding-top: 24px; }

.p-l-24 {
  padding-left: 24px; }

.p-r-24 {
  padding-right: 24px; }

.p-b-24 {
  padding-bottom: 24px; }

.m-24 {
  margin: 24px; }

.m-l-24 {
  margin-left: 24px; }

.m-l-n-24 {
  margin-left: -24px; }

.m-t-24 {
  margin-top: 24px; }

.m-t-n-24 {
  margin-top: -24px; }

.m-r-24 {
  margin-right: 24px; }

.m-r-n-24 {
  margin-right: -24px; }

.m-b-24 {
  margin-bottom: 24px; }

.m-b-n-24 {
  margin-bottom: -24px; }

.p-25 {
  padding: 25px; }

.p-t-25 {
  padding-top: 25px; }

.p-l-25 {
  padding-left: 25px; }

.p-r-25 {
  padding-right: 25px; }

.p-b-25 {
  padding-bottom: 25px; }

.m-25 {
  margin: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-l-n-25 {
  margin-left: -25px; }

.m-t-25 {
  margin-top: 25px; }

.m-t-n-25 {
  margin-top: -25px; }

.m-r-25 {
  margin-right: 25px; }

.m-r-n-25 {
  margin-right: -25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-b-n-25 {
  margin-bottom: -25px; }

.p-30 {
  padding: 30px; }

.p-t-30 {
  padding-top: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-r-30 {
  padding-right: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.m-30 {
  margin: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-l-n-30 {
  margin-left: -30px; }

.m-t-30 {
  margin-top: 30px; }

.m-t-n-30 {
  margin-top: -30px; }

.m-r-30 {
  margin-right: 30px; }

.m-r-n-30 {
  margin-right: -30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-b-n-30 {
  margin-bottom: -30px; }

.p-32 {
  padding: 32px; }

.p-t-32 {
  padding-top: 32px; }

.p-l-32 {
  padding-left: 32px; }

.p-r-32 {
  padding-right: 32px; }

.p-b-32 {
  padding-bottom: 32px; }

.m-32 {
  margin: 32px; }

.m-l-32 {
  margin-left: 32px; }

.m-l-n-32 {
  margin-left: -32px; }

.m-t-32 {
  margin-top: 32px; }

.m-t-n-32 {
  margin-top: -32px; }

.m-r-32 {
  margin-right: 32px; }

.m-r-n-32 {
  margin-right: -32px; }

.m-b-32 {
  margin-bottom: 32px; }

.m-b-n-32 {
  margin-bottom: -32px; }

.p-40 {
  padding: 40px; }

.p-t-40 {
  padding-top: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-r-40 {
  padding-right: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.m-40 {
  margin: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-l-n-40 {
  margin-left: -40px; }

.m-t-40 {
  margin-top: 40px; }

.m-t-n-40 {
  margin-top: -40px; }

.m-r-40 {
  margin-right: 40px; }

.m-r-n-40 {
  margin-right: -40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-b-n-40 {
  margin-bottom: -40px; }

.p-44 {
  padding: 44px; }

.p-t-44 {
  padding-top: 44px; }

.p-l-44 {
  padding-left: 44px; }

.p-r-44 {
  padding-right: 44px; }

.p-b-44 {
  padding-bottom: 44px; }

.m-44 {
  margin: 44px; }

.m-l-44 {
  margin-left: 44px; }

.m-l-n-44 {
  margin-left: -44px; }

.m-t-44 {
  margin-top: 44px; }

.m-t-n-44 {
  margin-top: -44px; }

.m-r-44 {
  margin-right: 44px; }

.m-r-n-44 {
  margin-right: -44px; }

.m-b-44 {
  margin-bottom: 44px; }

.m-b-n-44 {
  margin-bottom: -44px; }

.p-50 {
  padding: 50px; }

.p-t-50 {
  padding-top: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-r-50 {
  padding-right: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.m-50 {
  margin: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-l-n-50 {
  margin-left: -50px; }

.m-t-50 {
  margin-top: 50px; }

.m-t-n-50 {
  margin-top: -50px; }

.m-r-50 {
  margin-right: 50px; }

.m-r-n-50 {
  margin-right: -50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-b-n-50 {
  margin-bottom: -50px; }

.p-52 {
  padding: 52px; }

.p-t-52 {
  padding-top: 52px; }

.p-l-52 {
  padding-left: 52px; }

.p-r-52 {
  padding-right: 52px; }

.p-b-52 {
  padding-bottom: 52px; }

.m-52 {
  margin: 52px; }

.m-l-52 {
  margin-left: 52px; }

.m-l-n-52 {
  margin-left: -52px; }

.m-t-52 {
  margin-top: 52px; }

.m-t-n-52 {
  margin-top: -52px; }

.m-r-52 {
  margin-right: 52px; }

.m-r-n-52 {
  margin-right: -52px; }

.m-b-52 {
  margin-bottom: 52px; }

.m-b-n-52 {
  margin-bottom: -52px; }

.p-60 {
  padding: 60px; }

.p-t-60 {
  padding-top: 60px; }

.p-l-60 {
  padding-left: 60px; }

.p-r-60 {
  padding-right: 60px; }

.p-b-60 {
  padding-bottom: 60px; }

.m-60 {
  margin: 60px; }

.m-l-60 {
  margin-left: 60px; }

.m-l-n-60 {
  margin-left: -60px; }

.m-t-60 {
  margin-top: 60px; }

.m-t-n-60 {
  margin-top: -60px; }

.m-r-60 {
  margin-right: 60px; }

.m-r-n-60 {
  margin-right: -60px; }

.m-b-60 {
  margin-bottom: 60px; }

.m-b-n-60 {
  margin-bottom: -60px; }

.p-80 {
  padding: 80px; }

.p-t-80 {
  padding-top: 80px; }

.p-l-80 {
  padding-left: 80px; }

.p-r-80 {
  padding-right: 80px; }

.p-b-80 {
  padding-bottom: 80px; }

.m-80 {
  margin: 80px; }

.m-l-80 {
  margin-left: 80px; }

.m-l-n-80 {
  margin-left: -80px; }

.m-t-80 {
  margin-top: 80px; }

.m-t-n-80 {
  margin-top: -80px; }

.m-r-80 {
  margin-right: 80px; }

.m-r-n-80 {
  margin-right: -80px; }

.m-b-80 {
  margin-bottom: 80px; }

.m-b-n-80 {
  margin-bottom: -80px; }

.inline {
  display: inline-block; }

.inline-only {
  display: inline; }

.table-cell {
  display: table-cell; }

/*
* PROGRESS BAR
*/
.progress-container > div {
  margin-bottom: 10px; }

.progress-container .progress {
  background-color: #DBDBDB;
  height: 2px; }
  .progress-container .progress.md {
    height: 4px; }

/*
* BACKGROUND COLOR
*/
.bg-success {
  background-color: #7C9A76; }

.bg-danger {
  background-color: #EA4D4D; }

.bg-info {
  background-color: #2F80ED; }

.bg-header-gray {
  background-color: #F1F1F1; }

.bg-body {
  background-color: #F2F2F2; }

.bg-white {
  background-color: #FFFFFF; }

.bg-light-blue {
  background-color: #BDE6EC; }

.bg-purple {
  background-color: #564FDB; }

.bg-dark {
  background-color: #333333; }

.success {
  background-color: #7C9A76; }

.danger {
  background-color: #E36B6B; }

.info {
  background-color: #6472C7; }

.box-shadow {
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.19), 1px 8px 20px 4px rgba(169, 165, 165, 0.12); }

.line-height-sm {
  line-height: 1.2; }

.line-height-md {
  line-height: 1.4; }

.line-height-l {
  line-height: 2.4; }

.line-height-1 {
  line-height: 1; }

.full-width {
  width: 100%; }

.max-width {
  max-width: 100%; }

.bg-color-transparent {
  background-color: transparent; }

.input-thankyou {
  width: 100%;
  height: 35px;
  border-radius: 3.5px;
  border: 1px solid #333333;
  padding: 5px 10px;
  font-size: 16px; }

.input-thankyou::-webkit-input-placeholder {
  color: #B7B7B7 !important; }

/* WebKit, Blink, Edge */
.input-thankyou:-moz-placeholder {
  color: #B7B7B7 !important; }

/* Mozilla Firefox 4 to 18 */
.input-thankyou::-moz-placeholder {
  color: #B7B7B7 !important; }

/* Mozilla Firefox 19+ */
.input-thankyou:-ms-input-placeholder {
  color: #B7B7B7 !important; }

/* Internet Explorer 10-11 */
.input-thankyou::-ms-input-placeholder {
  color: #B7B7B7 !important; }

/* Microsoft Edge */
.input-thankyou-link {
  width: 86%;
  font-size: 13px;
  height: 35px;
  font-weight: bold;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #333333;
  padding: 5px 10px; }

.div-thankyou {
  padding-bottom: 10px; }

.button-save {
  background-color: #564fdb;
  border: 1px solid #564fdb;
  border-radius: 5px;
  color: #fff;
  min-width: 106px;
  height: 37px;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 20px; }

.button-copy {
  background-color: #564fdb;
  border: 1px solid #564fdb;
  border-radius: 0px 5px 5px 0px;
  color: #fff;
  width: 100px;
  min-width: 60px;
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  vertical-align: top; }

.form-thankyou {
  display: contents; }

.input-error {
  border-color: #EA4D4D;
  color: #EA4D4D; }

.profilelink {
  font-size: 15px;
  color: #333333; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* ICONS, IMAGES
*
*/
.icon, .icon-check, .icon-clock, .icon-video, .icon-test, .icon-timer, .icon-attempt, .icon-next, .icon-previous, .icon-i-download-material {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.icon--sm {
  width: 14px;
  height: 14px; }

.icon--md {
  width: 18px;
  height: 18px; }

.icon--lg {
  width: 24px;
  height: 24px; }

.icon--xl {
  width: 34px;
  height: 34px; }

.img-responsive {
  margin: 0 auto !important;
  text-align: center !important; }

.icon-check {
  background: url(../../images/check.png) no-repeat; }

.icon-clock {
  background: url(../../images/time.svg) no-repeat; }

.icon-video {
  background: url(../../images/video.svg) no-repeat; }

.icon-play-purple {
  width: 28px;
  height: 20px;
  background: url(../../images/play-purple.svg) no-repeat;
  margin-right: 20px; }

.icon-play-button-purple {
  width: 32px;
  height: 32px;
  background: url(../../images/play-button-purple32.png) no-repeat; }

.icon-test {
  background: url(../../images/test.svg) no-repeat; }

.icon-ftq {
  width: 25px;
  height: 28px;
  background: url(../../images/ftq.png) no-repeat; }

.icon-zoom-hover {
  width: 34px;
  height: 34px;
  display: inline-block;
  background: url(../../images/zoom-hover.png) no-repeat; }

.icon-timer {
  background: url(../../images/timer.svg) no-repeat; }

.icon-attempt {
  background: url(../../images/attempt.svg) no-repeat; }

.icon-next {
  background: url(../../images/next.svg) no-repeat; }

.icon-previous {
  background: url(../../images/previous.svg) no-repeat; }

.img-retry, .btn-retry {
  width: 35px;
  height: 35px;
  display: inline-block;
  background: url(../../images/retake.svg) no-repeat; }

.img-no-retry, .btn-retry:disabled {
  width: 35px;
  height: 35px;
  display: inline-block;
  background: url(../../images/retake-inactive.svg) no-repeat; }

.img-play, .btn-play {
  width: 59px;
  height: 59px;
  display: inline-block;
  background: url(../../images/play.svg) no-repeat; }

.img-pause, .btn-pause {
  width: 59px;
  height: 59px;
  display: inline-block;
  background: url(../../images/pause.svg) no-repeat; }

.img-checklist, .btn-checklist {
  width: 35px;
  height: 35px;
  display: inline-block;
  background: url(../../images/done.svg) no-repeat; }

.icon-video-off {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url(../../images/video-off.png) no-repeat; }

.icon-mic-off {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url(../../images/mic-off.png) no-repeat; }

.icon-change-mind {
  width: 200px;
  height: 200px;
  display: inline-block;
  background: url(../../images/change-mind.png) no-repeat; }

.icon-goodbye {
  width: 200px;
  height: 200px;
  display: inline-block;
  background: url(../../images/goodbye.png) no-repeat; }

.icon-no-email {
  width: 200px;
  height: 200px;
  display: inline-block;
  background: url(../../images/no-email.png) no-repeat; }

.icon-kognisi-final {
  width: 225px;
  height: 90px;
  margin: 80px 0;
  display: inline-block;
  background: url(../../images/logo-kognisi-final.png) no-repeat;
  background-size: 225px 90px; }

.icon-check-document {
  width: 150px;
  height: 150px;
  display: inline-block;
  background: url(../../images/check-document.png) no-repeat; }

.icon-mic {
  width: 40px;
  height: 60px;
  display: inline-block;
  background: url(../../images/microphone.svg) no-repeat; }

.icon-video-yt {
  width: 21px;
  height: 14px;
  display: inline-block;
  background: url(../../images/video-yt.svg) no-repeat; }

.icon-i-download-material {
  width: 18px;
  height: 18px;
  background: url(../../images/download-material.svg) no-repeat;
  background-size: 18px;
  margin-left: 5px;
  cursor: pointer; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* BUTTON
*
*/
/*
*
* ASTRONAUT PUBLIC WEB APP
* VARIABLES
*
*/
.text-white {
  color: #FFFFFF; }

.text-purple {
  color: #564FDB; }

.text-link {
  color: #132ED5; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* MIXINS
*
*/
/*
*
* ASTRONAUT PUBLIC WEB APP
* BASE
*
*/
/*
*
* ASTRONAUT PUBLIC WEB APP
* VARIABLES
*
*/
.text-white {
  color: #FFFFFF; }

.text-purple {
  color: #564FDB; }

.text-link {
  color: #132ED5; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* MIXINS
*
*/
html, body {
  width: 100%;
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.57142857;
  background: #F2F2F2;
  color: #333333; }

a {
  cursor: pointer;
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: none; }

button {
  border: 0; }

button, button:focus, .btn, .btn:focus {
  outline: none !important;
  outline: 0;
  cursor: pointer; }

.hide {
  display: none !important; }

.hidden {
  display: none; }

.no-margin {
  margin: 0; }

.no-rounded-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fixed {
  position: fixed; }

/*
* FLEX CONTAINER
*/
.flex-container, .vertical-item--center, .panel, .vertical-item--top, .vertical-item--justify, .horizontal-item--around, .horizontal-item--between, .horizontal-item--evenly, .horizontal-item--center, .indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-between, .horizontal-item--between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.flex-evenly, .horizontal-item--evenly {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-evenly; }

.flex-start, .vertical-item--justify {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-center, .horizontal-item--center, .card__note, .card--loading {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.align-items-center, .vertical-item--center, .panel, .horizontal-item--around, .horizontal-item--between, .horizontal-item--evenly, .horizontal-item--center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.align-items-start, .vertical-item--top {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start; }

.align-items-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end; }

.flex-row {
  flex-direction: row; }

.flex-grow {
  flex-grow: 1;
  flex: 1; }

.flex-column {
  flex-direction: column; }

.navbar {
  background: #FFFFFF;
  border-bottom: 1px solid #3E3C82; }

.navbar-header {
  display: block;
  opacity: 1;
  padding: 21px 0; }
  .navbar-header img {
    text-align: left;
    margin: 0;
    max-height: 37px; }

.block {
  display: block; }

.center {
  margin: 0 auto;
  text-align: center; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.valign-middle {
  vertical-align: middle; }

.valign-bottom {
  vertical-align: bottom; }

.valign-top {
  vertical-align: top; }

.max-w-100 {
  max-width: 100%; }

.w-100 {
  width: 100%; }

.float-right {
  float: right; }

.color-666 {
  color: #666666; }

.color-999 {
  color: #999999; }

/*
* MARGINS & PADDINGS
*/
.p-0 {
  padding: 0px; }

.p-t-0 {
  padding-top: 0px; }

.p-l-0 {
  padding-left: 0px; }

.p-r-0 {
  padding-right: 0px; }

.p-b-0 {
  padding-bottom: 0px; }

.m-0 {
  margin: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-l-n-0 {
  margin-left: -0px; }

.m-t-0 {
  margin-top: 0px; }

.m-t-n-0 {
  margin-top: -0px; }

.m-r-0 {
  margin-right: 0px; }

.m-r-n-0 {
  margin-right: -0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-b-n-0 {
  margin-bottom: -0px; }

.p-4 {
  padding: 4px; }

.p-t-4 {
  padding-top: 4px; }

.p-l-4 {
  padding-left: 4px; }

.p-r-4 {
  padding-right: 4px; }

.p-b-4 {
  padding-bottom: 4px; }

.m-4 {
  margin: 4px; }

.m-l-4 {
  margin-left: 4px; }

.m-l-n-4 {
  margin-left: -4px; }

.m-t-4 {
  margin-top: 4px; }

.m-t-n-4 {
  margin-top: -4px; }

.m-r-4 {
  margin-right: 4px; }

.m-r-n-4 {
  margin-right: -4px; }

.m-b-4 {
  margin-bottom: 4px; }

.m-b-n-4 {
  margin-bottom: -4px; }

.p-5 {
  padding: 5px; }

.p-t-5 {
  padding-top: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.m-5 {
  margin: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-l-n-5 {
  margin-left: -5px; }

.m-t-5 {
  margin-top: 5px; }

.m-t-n-5 {
  margin-top: -5px; }

.m-r-5 {
  margin-right: 5px; }

.m-r-n-5 {
  margin-right: -5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-b-n-5 {
  margin-bottom: -5px; }

.p-6 {
  padding: 6px; }

.p-t-6 {
  padding-top: 6px; }

.p-l-6 {
  padding-left: 6px; }

.p-r-6 {
  padding-right: 6px; }

.p-b-6 {
  padding-bottom: 6px; }

.m-6 {
  margin: 6px; }

.m-l-6 {
  margin-left: 6px; }

.m-l-n-6 {
  margin-left: -6px; }

.m-t-6 {
  margin-top: 6px; }

.m-t-n-6 {
  margin-top: -6px; }

.m-r-6 {
  margin-right: 6px; }

.m-r-n-6 {
  margin-right: -6px; }

.m-b-6 {
  margin-bottom: 6px; }

.m-b-n-6 {
  margin-bottom: -6px; }

.p-8 {
  padding: 8px; }

.p-t-8 {
  padding-top: 8px; }

.p-l-8 {
  padding-left: 8px; }

.p-r-8 {
  padding-right: 8px; }

.p-b-8 {
  padding-bottom: 8px; }

.m-8 {
  margin: 8px; }

.m-l-8 {
  margin-left: 8px; }

.m-l-n-8 {
  margin-left: -8px; }

.m-t-8 {
  margin-top: 8px; }

.m-t-n-8 {
  margin-top: -8px; }

.m-r-8 {
  margin-right: 8px; }

.m-r-n-8 {
  margin-right: -8px; }

.m-b-8 {
  margin-bottom: 8px; }

.m-b-n-8 {
  margin-bottom: -8px; }

.p-10 {
  padding: 10px; }

.p-t-10 {
  padding-top: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.m-10 {
  margin: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-l-n-10 {
  margin-left: -10px; }

.m-t-10 {
  margin-top: 10px; }

.m-t-n-10 {
  margin-top: -10px; }

.m-r-10 {
  margin-right: 10px; }

.m-r-n-10 {
  margin-right: -10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-b-n-10 {
  margin-bottom: -10px; }

.p-12 {
  padding: 12px; }

.p-t-12 {
  padding-top: 12px; }

.p-l-12 {
  padding-left: 12px; }

.p-r-12 {
  padding-right: 12px; }

.p-b-12 {
  padding-bottom: 12px; }

.m-12 {
  margin: 12px; }

.m-l-12 {
  margin-left: 12px; }

.m-l-n-12 {
  margin-left: -12px; }

.m-t-12 {
  margin-top: 12px; }

.m-t-n-12 {
  margin-top: -12px; }

.m-r-12 {
  margin-right: 12px; }

.m-r-n-12 {
  margin-right: -12px; }

.m-b-12 {
  margin-bottom: 12px; }

.m-b-n-12 {
  margin-bottom: -12px; }

.p-15 {
  padding: 15px; }

.p-t-15 {
  padding-top: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.m-15 {
  margin: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-l-n-15 {
  margin-left: -15px; }

.m-t-15 {
  margin-top: 15px; }

.m-t-n-15 {
  margin-top: -15px; }

.m-r-15 {
  margin-right: 15px; }

.m-r-n-15 {
  margin-right: -15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-b-n-15 {
  margin-bottom: -15px; }

.p-16 {
  padding: 16px; }

.p-t-16 {
  padding-top: 16px; }

.p-l-16 {
  padding-left: 16px; }

.p-r-16 {
  padding-right: 16px; }

.p-b-16 {
  padding-bottom: 16px; }

.m-16 {
  margin: 16px; }

.m-l-16 {
  margin-left: 16px; }

.m-l-n-16 {
  margin-left: -16px; }

.m-t-16 {
  margin-top: 16px; }

.m-t-n-16 {
  margin-top: -16px; }

.m-r-16 {
  margin-right: 16px; }

.m-r-n-16 {
  margin-right: -16px; }

.m-b-16 {
  margin-bottom: 16px; }

.m-b-n-16 {
  margin-bottom: -16px; }

.p-20 {
  padding: 20px; }

.p-t-20 {
  padding-top: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.m-20 {
  margin: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-l-n-20 {
  margin-left: -20px; }

.m-t-20 {
  margin-top: 20px; }

.m-t-n-20 {
  margin-top: -20px; }

.m-r-20 {
  margin-right: 20px; }

.m-r-n-20 {
  margin-right: -20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-b-n-20 {
  margin-bottom: -20px; }

.p-22 {
  padding: 22px; }

.p-t-22 {
  padding-top: 22px; }

.p-l-22 {
  padding-left: 22px; }

.p-r-22 {
  padding-right: 22px; }

.p-b-22 {
  padding-bottom: 22px; }

.m-22 {
  margin: 22px; }

.m-l-22 {
  margin-left: 22px; }

.m-l-n-22 {
  margin-left: -22px; }

.m-t-22 {
  margin-top: 22px; }

.m-t-n-22 {
  margin-top: -22px; }

.m-r-22 {
  margin-right: 22px; }

.m-r-n-22 {
  margin-right: -22px; }

.m-b-22 {
  margin-bottom: 22px; }

.m-b-n-22 {
  margin-bottom: -22px; }

.p-24 {
  padding: 24px; }

.p-t-24 {
  padding-top: 24px; }

.p-l-24 {
  padding-left: 24px; }

.p-r-24 {
  padding-right: 24px; }

.p-b-24 {
  padding-bottom: 24px; }

.m-24 {
  margin: 24px; }

.m-l-24 {
  margin-left: 24px; }

.m-l-n-24 {
  margin-left: -24px; }

.m-t-24 {
  margin-top: 24px; }

.m-t-n-24 {
  margin-top: -24px; }

.m-r-24 {
  margin-right: 24px; }

.m-r-n-24 {
  margin-right: -24px; }

.m-b-24 {
  margin-bottom: 24px; }

.m-b-n-24 {
  margin-bottom: -24px; }

.p-25 {
  padding: 25px; }

.p-t-25 {
  padding-top: 25px; }

.p-l-25 {
  padding-left: 25px; }

.p-r-25 {
  padding-right: 25px; }

.p-b-25 {
  padding-bottom: 25px; }

.m-25 {
  margin: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-l-n-25 {
  margin-left: -25px; }

.m-t-25 {
  margin-top: 25px; }

.m-t-n-25 {
  margin-top: -25px; }

.m-r-25 {
  margin-right: 25px; }

.m-r-n-25 {
  margin-right: -25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-b-n-25 {
  margin-bottom: -25px; }

.p-30 {
  padding: 30px; }

.p-t-30 {
  padding-top: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-r-30 {
  padding-right: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.m-30 {
  margin: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-l-n-30 {
  margin-left: -30px; }

.m-t-30 {
  margin-top: 30px; }

.m-t-n-30 {
  margin-top: -30px; }

.m-r-30 {
  margin-right: 30px; }

.m-r-n-30 {
  margin-right: -30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-b-n-30 {
  margin-bottom: -30px; }

.p-32 {
  padding: 32px; }

.p-t-32 {
  padding-top: 32px; }

.p-l-32 {
  padding-left: 32px; }

.p-r-32 {
  padding-right: 32px; }

.p-b-32 {
  padding-bottom: 32px; }

.m-32 {
  margin: 32px; }

.m-l-32 {
  margin-left: 32px; }

.m-l-n-32 {
  margin-left: -32px; }

.m-t-32 {
  margin-top: 32px; }

.m-t-n-32 {
  margin-top: -32px; }

.m-r-32 {
  margin-right: 32px; }

.m-r-n-32 {
  margin-right: -32px; }

.m-b-32 {
  margin-bottom: 32px; }

.m-b-n-32 {
  margin-bottom: -32px; }

.p-40 {
  padding: 40px; }

.p-t-40 {
  padding-top: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-r-40 {
  padding-right: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.m-40 {
  margin: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-l-n-40 {
  margin-left: -40px; }

.m-t-40 {
  margin-top: 40px; }

.m-t-n-40 {
  margin-top: -40px; }

.m-r-40 {
  margin-right: 40px; }

.m-r-n-40 {
  margin-right: -40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-b-n-40 {
  margin-bottom: -40px; }

.p-44 {
  padding: 44px; }

.p-t-44 {
  padding-top: 44px; }

.p-l-44 {
  padding-left: 44px; }

.p-r-44 {
  padding-right: 44px; }

.p-b-44 {
  padding-bottom: 44px; }

.m-44 {
  margin: 44px; }

.m-l-44 {
  margin-left: 44px; }

.m-l-n-44 {
  margin-left: -44px; }

.m-t-44 {
  margin-top: 44px; }

.m-t-n-44 {
  margin-top: -44px; }

.m-r-44 {
  margin-right: 44px; }

.m-r-n-44 {
  margin-right: -44px; }

.m-b-44 {
  margin-bottom: 44px; }

.m-b-n-44 {
  margin-bottom: -44px; }

.p-50 {
  padding: 50px; }

.p-t-50 {
  padding-top: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-r-50 {
  padding-right: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.m-50 {
  margin: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-l-n-50 {
  margin-left: -50px; }

.m-t-50 {
  margin-top: 50px; }

.m-t-n-50 {
  margin-top: -50px; }

.m-r-50 {
  margin-right: 50px; }

.m-r-n-50 {
  margin-right: -50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-b-n-50 {
  margin-bottom: -50px; }

.p-52 {
  padding: 52px; }

.p-t-52 {
  padding-top: 52px; }

.p-l-52 {
  padding-left: 52px; }

.p-r-52 {
  padding-right: 52px; }

.p-b-52 {
  padding-bottom: 52px; }

.m-52 {
  margin: 52px; }

.m-l-52 {
  margin-left: 52px; }

.m-l-n-52 {
  margin-left: -52px; }

.m-t-52 {
  margin-top: 52px; }

.m-t-n-52 {
  margin-top: -52px; }

.m-r-52 {
  margin-right: 52px; }

.m-r-n-52 {
  margin-right: -52px; }

.m-b-52 {
  margin-bottom: 52px; }

.m-b-n-52 {
  margin-bottom: -52px; }

.p-60 {
  padding: 60px; }

.p-t-60 {
  padding-top: 60px; }

.p-l-60 {
  padding-left: 60px; }

.p-r-60 {
  padding-right: 60px; }

.p-b-60 {
  padding-bottom: 60px; }

.m-60 {
  margin: 60px; }

.m-l-60 {
  margin-left: 60px; }

.m-l-n-60 {
  margin-left: -60px; }

.m-t-60 {
  margin-top: 60px; }

.m-t-n-60 {
  margin-top: -60px; }

.m-r-60 {
  margin-right: 60px; }

.m-r-n-60 {
  margin-right: -60px; }

.m-b-60 {
  margin-bottom: 60px; }

.m-b-n-60 {
  margin-bottom: -60px; }

.p-80 {
  padding: 80px; }

.p-t-80 {
  padding-top: 80px; }

.p-l-80 {
  padding-left: 80px; }

.p-r-80 {
  padding-right: 80px; }

.p-b-80 {
  padding-bottom: 80px; }

.m-80 {
  margin: 80px; }

.m-l-80 {
  margin-left: 80px; }

.m-l-n-80 {
  margin-left: -80px; }

.m-t-80 {
  margin-top: 80px; }

.m-t-n-80 {
  margin-top: -80px; }

.m-r-80 {
  margin-right: 80px; }

.m-r-n-80 {
  margin-right: -80px; }

.m-b-80 {
  margin-bottom: 80px; }

.m-b-n-80 {
  margin-bottom: -80px; }

.inline {
  display: inline-block; }

.inline-only {
  display: inline; }

.table-cell {
  display: table-cell; }

/*
* PROGRESS BAR
*/
.progress-container > div {
  margin-bottom: 10px; }

.progress-container .progress {
  background-color: #DBDBDB;
  height: 2px; }
  .progress-container .progress.md {
    height: 4px; }

/*
* BACKGROUND COLOR
*/
.bg-success {
  background-color: #7C9A76; }

.bg-danger {
  background-color: #EA4D4D; }

.bg-info {
  background-color: #2F80ED; }

.bg-header-gray {
  background-color: #F1F1F1; }

.bg-body {
  background-color: #F2F2F2; }

.bg-white {
  background-color: #FFFFFF; }

.bg-light-blue {
  background-color: #BDE6EC; }

.bg-purple {
  background-color: #564FDB; }

.bg-dark {
  background-color: #333333; }

.success {
  background-color: #7C9A76; }

.danger {
  background-color: #E36B6B; }

.info {
  background-color: #6472C7; }

.box-shadow {
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.19), 1px 8px 20px 4px rgba(169, 165, 165, 0.12); }

.line-height-sm {
  line-height: 1.2; }

.line-height-md {
  line-height: 1.4; }

.line-height-l {
  line-height: 2.4; }

.line-height-1 {
  line-height: 1; }

.full-width {
  width: 100%; }

.max-width {
  max-width: 100%; }

.bg-color-transparent {
  background-color: transparent; }

.input-thankyou {
  width: 100%;
  height: 35px;
  border-radius: 3.5px;
  border: 1px solid #333333;
  padding: 5px 10px;
  font-size: 16px; }

.input-thankyou::-webkit-input-placeholder {
  color: #B7B7B7 !important; }

/* WebKit, Blink, Edge */
.input-thankyou:-moz-placeholder {
  color: #B7B7B7 !important; }

/* Mozilla Firefox 4 to 18 */
.input-thankyou::-moz-placeholder {
  color: #B7B7B7 !important; }

/* Mozilla Firefox 19+ */
.input-thankyou:-ms-input-placeholder {
  color: #B7B7B7 !important; }

/* Internet Explorer 10-11 */
.input-thankyou::-ms-input-placeholder {
  color: #B7B7B7 !important; }

/* Microsoft Edge */
.input-thankyou-link {
  width: 86%;
  font-size: 13px;
  height: 35px;
  font-weight: bold;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #333333;
  padding: 5px 10px; }

.div-thankyou {
  padding-bottom: 10px; }

.button-save {
  background-color: #564fdb;
  border: 1px solid #564fdb;
  border-radius: 5px;
  color: #fff;
  min-width: 106px;
  height: 37px;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 20px; }

.button-copy {
  background-color: #564fdb;
  border: 1px solid #564fdb;
  border-radius: 0px 5px 5px 0px;
  color: #fff;
  width: 100px;
  min-width: 60px;
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  vertical-align: top; }

.form-thankyou {
  display: contents; }

.input-error {
  border-color: #EA4D4D;
  color: #EA4D4D; }

.profilelink {
  font-size: 15px;
  color: #333333; }

.button {
  background-color: #564FDB;
  border: 1px solid #564FDB;
  border-radius: 50px;
  outline: 3px solid transparent;
  color: #FFFFFF;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
  margin: 0;
  padding: 9px 20px;
  width: auto;
  min-width: 122px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  transition: all 300ms ease;
  box-shadow: 0 0 3px 3px transparent; }
  .button:hover {
    background-color: #6472C7; }
  .button:focus {
    background-color: #564FDB;
    outline-color: #D4BDFF;
    box-shadow: 0 0 3px 3px #D4BDFF; }
  .button:active {
    outline-color: #D4BDFF; }
  .button:disabled {
    background-color: #6472C7;
    opacity: 50;
    cursor: not-allowed; }
  .button--loading {
    background-color: #564FDB;
    box-shadow: none; }
    .button--loading:focus {
      box-shadow: none; }
  .button__primary {
    background-color: #564FDB; }
    .button__primary:hover {
      background-color: #6472C7; }
    .button__primary:focus {
      background-color: #564FDB;
      outline-color: #D4BDFF; }
    .button__primary:active {
      background-color: #564FDB; }
    .button__primary:disabled {
      background-color: #D4BDFF;
      border-color: #D4BDFF; }
  .button__secondary {
    background-color: transparent;
    border: 1px solid #564FDB;
    color: #564FDB; }
    .button__secondary:hover {
      background-color: transparent;
      border: 1px solid #564FDB; }
    .button__secondary:focus {
      background-color: transparent;
      outline-color: #D4BDFF;
      color: #564FDB; }
    .button__secondary:active {
      border: 1px solid #564FDB; }
    .button__secondary:disabled {
      background-color: transparent;
      opacity: 0.5; }
  .button__text-only {
    background-color: transparent;
    font-size: 16px;
    color: #FFFFFF;
    padding: 0;
    border: 0; }
    .button__text-only:hover, .button__text-only:focus, .button__text-only:active {
      outline-color: transparent !important;
      background-color: transparent; }
    .button__text-only:disabled {
      background: transparent;
      color: #FFFFFF;
      opacity: .5; }
  .button__danger {
    background-color: #EA4D4D;
    border-color: #EA4D4D; }
    .button__danger:hover {
      background-color: #F06262; }
    .button__danger:focus {
      background-color: #EA4D4D;
      outline-color: #D4BDFF; }
    .button__danger:active {
      background-color: #EA4D4D; }
    .button__danger:disabled {
      background-color: #EA4D4D;
      opacity: 0.5; }
  .button__image {
    background-color: transparent;
    min-width: inherit;
    border: 0;
    padding: 0; }
    .button__image:hover, .button__image:focus, .button__image:active {
      background-color: transparent;
      min-width: inherit;
      border: 0; }
  .button__toggle {
    padding: 10px;
    background-color: #6472C7;
    border-color: transparent;
    width: 100%;
    min-width: 80px; }
    .button__toggle:hover, .button__toggle:focus, .button__toggle:active {
      background-color: #6472C7;
      box-shadow: none; }
  .button.block {
    display: block; }
  .button--small {
    font-size: 12px;
    line-height: 16px;
    padding: 9px 20px; }
    .button--small [class^="icon"] {
      font-size: 10px; }
  .button--medium {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 20px;
    min-width: 92px; }
    .button--medium [class^="icon"] {
      font-size: 18px; }
  .button--large {
    font-size: 18px;
    line-height: 24px;
    padding: 9px 20px;
    min-width: 122px; }
    .button--large [class^="icon"] {
      font-size: 24px; }

.button--unrounded {
  border-radius: 0; }

.button-start-now {
  background-color: transparent;
  border: 3px solid #C4C4C4 !important;
  border-radius: 10px;
  font-size: 16px;
  color: #C5B3B3; }

.blue-link {
  color: #4A90E2;
  text-decoration: underline; }
  .blue-link:hover {
    color: #4A90E2;
    text-decoration: underline; }

.grey-link {
  color: #636363;
  text-decoration: underline; }
  .grey-link:hover {
    color: #636363;
    text-decoration: underline; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* ICONS, IMAGES
*
*/
.icon, .icon-check, .icon-clock, .icon-video, .icon-test, .icon-timer, .icon-attempt, .icon-next, .icon-previous, .icon-i-download-material {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle; }

.icon--sm {
  width: 14px;
  height: 14px; }

.icon--md {
  width: 18px;
  height: 18px; }

.icon--lg {
  width: 24px;
  height: 24px; }

.icon--xl {
  width: 34px;
  height: 34px; }

.img-responsive {
  margin: 0 auto !important;
  text-align: center !important; }

.icon-check {
  background: url(../../images/check.png) no-repeat; }

.icon-clock {
  background: url(../../images/time.svg) no-repeat; }

.icon-video {
  background: url(../../images/video.svg) no-repeat; }

.icon-play-purple {
  width: 28px;
  height: 20px;
  background: url(../../images/play-purple.svg) no-repeat;
  margin-right: 20px; }

.icon-play-button-purple {
  width: 32px;
  height: 32px;
  background: url(../../images/play-button-purple32.png) no-repeat; }

.icon-test {
  background: url(../../images/test.svg) no-repeat; }

.icon-ftq {
  width: 25px;
  height: 28px;
  background: url(../../images/ftq.png) no-repeat; }

.icon-zoom-hover {
  width: 34px;
  height: 34px;
  display: inline-block;
  background: url(../../images/zoom-hover.png) no-repeat; }

.icon-timer {
  background: url(../../images/timer.svg) no-repeat; }

.icon-attempt {
  background: url(../../images/attempt.svg) no-repeat; }

.icon-next {
  background: url(../../images/next.svg) no-repeat; }

.icon-previous {
  background: url(../../images/previous.svg) no-repeat; }

.img-retry, .btn-retry {
  width: 35px;
  height: 35px;
  display: inline-block;
  background: url(../../images/retake.svg) no-repeat; }

.img-no-retry, .btn-retry:disabled {
  width: 35px;
  height: 35px;
  display: inline-block;
  background: url(../../images/retake-inactive.svg) no-repeat; }

.img-play, .btn-play {
  width: 59px;
  height: 59px;
  display: inline-block;
  background: url(../../images/play.svg) no-repeat; }

.img-pause, .btn-pause {
  width: 59px;
  height: 59px;
  display: inline-block;
  background: url(../../images/pause.svg) no-repeat; }

.img-checklist, .btn-checklist {
  width: 35px;
  height: 35px;
  display: inline-block;
  background: url(../../images/done.svg) no-repeat; }

.icon-video-off {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url(../../images/video-off.png) no-repeat; }

.icon-mic-off {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url(../../images/mic-off.png) no-repeat; }

.icon-change-mind {
  width: 200px;
  height: 200px;
  display: inline-block;
  background: url(../../images/change-mind.png) no-repeat; }

.icon-goodbye {
  width: 200px;
  height: 200px;
  display: inline-block;
  background: url(../../images/goodbye.png) no-repeat; }

.icon-no-email {
  width: 200px;
  height: 200px;
  display: inline-block;
  background: url(../../images/no-email.png) no-repeat; }

.icon-kognisi-final {
  width: 225px;
  height: 90px;
  margin: 80px 0;
  display: inline-block;
  background: url(../../images/logo-kognisi-final.png) no-repeat;
  background-size: 225px 90px; }

.icon-check-document {
  width: 150px;
  height: 150px;
  display: inline-block;
  background: url(../../images/check-document.png) no-repeat; }

.icon-mic {
  width: 40px;
  height: 60px;
  display: inline-block;
  background: url(../../images/microphone.svg) no-repeat; }

.icon-video-yt {
  width: 21px;
  height: 14px;
  display: inline-block;
  background: url(../../images/video-yt.svg) no-repeat; }

.icon-i-download-material {
  width: 18px;
  height: 18px;
  background: url(../../images/download-material.svg) no-repeat;
  background-size: 18px;
  margin-left: 5px;
  cursor: pointer; }

.btn-retry {
  cursor: pointer; }

.btn-play {
  cursor: pointer; }

.btn-pause {
  cursor: pointer; }

.btn-checklist {
  cursor: pointer; }

.btn-xs-checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #465190;
  border-radius: 3px;
  background-color: #fff;
  padding: 0; }
  .btn-xs-checkbox.checked {
    border-color: #465190; }
    .btn-xs-checkbox.checked:after {
      font-family: 'fontAwesome';
      content: "\f00c";
      color: #000;
      font-size: 12px;
      position: relative;
      top: -4px; }
  .btn-xs-checkbox:hover {
    border: 1px solid #465190; }

.btn-record {
  border: 0;
  font-size: 18px;
  font-weight: bold;
  width: 350px;
  height: 40px;
  padding: 3px; }
  .btn-record.recording {
    background: #E74F51; }

.btn-no-record {
  border: 1px solid #E33434;
  color: #E33434;
  background: white;
  font-size: 18px;
  font-weight: bold;
  width: 165px;
  height: 40px;
  padding: 3px; }

.btn-yes-record {
  border: 0;
  font-size: 18px;
  font-weight: bold;
  width: 165px;
  height: 40px;
  padding: 3px; }

.btn-practice-record.disabled {
  color: #999999;
  border-color: #999999; }

.btn-start-session {
  padding: 20px 70px; }
  .btn-start-session.disabled {
    border: 0;
    background: #999999; }
  .btn-start-session.beli {
    padding: 20px 30px;
    font-size: 18px;
    font-weight: bold; }

.btn-kembali-kognisi {
  padding: 10px 35px;
  margin-top: 15px; }

.button-play-video {
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: medium none;
  color: #fff;
  display: inline-block;
  font-size: 47px;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  padding: 8px 16px;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  .button-play-video.grey {
    background: rgba(153, 153, 153, 0.75); }

.button-play-audio {
  background: #564FDB;
  border: medium none;
  color: #fff;
  display: block;
  font-size: 32px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  left: 0;
  margin: 0 auto;
  padding: 8px 16px; }
  .button-play-audio.grey {
    background: #999999; }
  .button-play-audio .fa-volume-up {
    font-size: 32px; }

.button-post-rating {
  width: 35px;
  height: 35px;
  background: transparent;
  border-radius: 3px;
  border: thin solid #999999;
  color: #564FDB;
  font-weight: bold;
  transform: scaleX(-1); }
  .button-post-rating:hover {
    background-color: #564FDB;
    color: white; }
  .button-post-rating:focus {
    background-color: #564FDB;
    color: white; }
  .button-post-rating:active {
    background-color: #564FDB;
    color: white; }
  .button-post-rating.active {
    background-color: #564FDB;
    color: white; }

.button-post-rating-mobile {
  width: 35px;
  height: 35px;
  background: transparent;
  border-radius: 3px;
  border: thin solid #999999;
  color: #564FDB;
  font-weight: bold;
  transform: scaleX(-1); }
  .button-post-rating-mobile:hover {
    background-color: #564FDB;
    color: white; }
  .button-post-rating-mobile:focus {
    background-color: #564FDB;
    color: white; }
  .button-post-rating-mobile:active {
    background-color: #564FDB;
    color: white; }
  .button-post-rating-mobile.active {
    background-color: #564FDB;
    color: white; }

.btn-next-rating {
  padding: 6px 57px;
  font-weight: bold; }
  .btn-next-rating:disabled {
    background-color: #AAA7ED;
    border-color: #AAA7ED;
    color: #FFFFFF; }

.button-submit-course {
  width: 100%;
  min-width: 100%;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  padding: 15px 0; }
  .button-submit-course:focus, .button-submit-course:hover, .button-submit-course:active {
    opacity: 1;
    background-color: #564FDB; }

.button-submit-course:disabled {
  background-color: #999999;
  opacity: 1; }

.vertical-buttons {
  margin: 20px 0 0 0 !important;
  flex-direction: column;
  align-items: center; }

.vertical-buttons > button {
  width: 80%;
  font-weight: bold; }

.top-margin {
  margin-top: .5em; }

.font-15 {
  font-size: 15px;
  margin: 0 25px 0 25px; }

.font-15 > div > p {
  font-size: 15px;
  margin: 10px 20px 10px 20px; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* MIXINS
*
*/
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0; }
  8% {
    -webkit-transform: scale(0);
    opacity: 0; }
  15% {
    -webkit-transform: scale(0.1);
    opacity: 1; }
  30% {
    -webkit-transform: scale(0.5);
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: scale(1); } }

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(0);
    opacity: 0; }
  8% {
    -moz-transform: scale(0);
    opacity: 0; }
  15% {
    -moz-transform: scale(0.1);
    opacity: 1; }
  30% {
    -moz-transform: scale(0.5);
    opacity: 1; }
  100% {
    opacity: 0;
    -moz-transform: scale(1); } }

@keyframes blink {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }

/*
*
* ASTRONAUT WEB APP
* ELEMENTS
*
*/
/*
*
* ASTRONAUT PUBLIC WEB APP
* VARIABLES
*
*/
.text-white {
  color: #FFFFFF; }

.text-purple {
  color: #564FDB; }

.text-link {
  color: #132ED5; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* TYPOGRAPHY
*
*/
.heading1, .heading2, .card .card-title, .heading3, .heading4, .card .card-text, .heading5, .heading6 {
  margin: 0; }

.heading1 {
  font-size: 32px;
  line-height: 52px;
  font-weight: bold; }
  .heading1.larger {
    font-size: 42px;
    line-height: 52px;
    font-weight: bold; }

.heading2, .card .card-title {
  font-size: 24px;
  line-height: 38px;
  font-weight: bold; }

.heading3 {
  font-size: 22px;
  line-height: 36px;
  font-weight: bold; }

.heading4, .card .card-text {
  font-size: 18px;
  line-height: 32px;
  font-weight: normal; }

.heading5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600; }

.heading6 {
  font-size: 14px;
  line-height: 16px;
  font-weight: normal; }

p {
  font-size: 12px;
  line-height: 18px;
  font-weight: normal; }

.bold {
  font-weight: bold; }

.bold-important {
  font-weight: bold; }
  .bold-important span {
    font-weight: bold; }

.semibold {
  font-weight: 600; }

.normal {
  font-weight: normal; }

.small-text {
  font-size: 14px;
  font-weight: normal; }

.uppercase {
  text-transform: uppercase; }

.capitalize {
  text-transform: capitalize; }

p {
  margin-bottom: 0; }

.underline {
  text-decoration: underline; }

.heading-ftq {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px; }

.button-impress {
  background-color: #395723;
  color: white;
  margin: auto; }
  .button-impress:hover, .button-impress:focus, .button-impress:active {
    background-color: #395723; }

.p-test-mic {
  color: #666666;
  font-size: 12px; }

.course-reset-text {
  font-size: 20px;
  line-height: 24px;
  color: #707070; }

.a-action {
  color: #554AE4;
  font-weight: bold;
  cursor: pointer; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* BASE
*
*/
/*
*
* ASTRONAUT PUBLIC WEB APP
* VARIABLES
*
*/
.text-white {
  color: #FFFFFF; }

.text-purple {
  color: #564FDB; }

.text-link {
  color: #132ED5; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* MIXINS
*
*/
html, body {
  width: 100%;
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.57142857;
  background: #F2F2F2;
  color: #333333; }

a {
  cursor: pointer;
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: none; }

button {
  border: 0; }

button, button:focus, .btn, .btn:focus {
  outline: none !important;
  outline: 0;
  cursor: pointer; }

.hide {
  display: none !important; }

.hidden {
  display: none; }

.no-margin {
  margin: 0; }

.no-rounded-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fixed {
  position: fixed; }

/*
* FLEX CONTAINER
*/
.flex-container, .vertical-item--center, .panel, .vertical-item--top, .vertical-item--justify, .horizontal-item--around, .horizontal-item--between, .horizontal-item--evenly, .horizontal-item--center, .indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-between, .horizontal-item--between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.flex-evenly, .horizontal-item--evenly {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-evenly; }

.flex-start, .vertical-item--justify {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-center, .horizontal-item--center, .card__note, .card--loading {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.align-items-center, .vertical-item--center, .panel, .horizontal-item--around, .horizontal-item--between, .horizontal-item--evenly, .horizontal-item--center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.align-items-start, .vertical-item--top {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start; }

.align-items-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end; }

.flex-row {
  flex-direction: row; }

.flex-grow {
  flex-grow: 1;
  flex: 1; }

.flex-column {
  flex-direction: column; }

.navbar {
  background: #FFFFFF;
  border-bottom: 1px solid #3E3C82; }

.navbar-header {
  display: block;
  opacity: 1;
  padding: 21px 0; }
  .navbar-header img {
    text-align: left;
    margin: 0;
    max-height: 37px; }

.block {
  display: block; }

.center {
  margin: 0 auto;
  text-align: center; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.valign-middle {
  vertical-align: middle; }

.valign-bottom {
  vertical-align: bottom; }

.valign-top {
  vertical-align: top; }

.max-w-100 {
  max-width: 100%; }

.w-100 {
  width: 100%; }

.float-right {
  float: right; }

.color-666 {
  color: #666666; }

.color-999 {
  color: #999999; }

/*
* MARGINS & PADDINGS
*/
.p-0 {
  padding: 0px; }

.p-t-0 {
  padding-top: 0px; }

.p-l-0 {
  padding-left: 0px; }

.p-r-0 {
  padding-right: 0px; }

.p-b-0 {
  padding-bottom: 0px; }

.m-0 {
  margin: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-l-n-0 {
  margin-left: -0px; }

.m-t-0 {
  margin-top: 0px; }

.m-t-n-0 {
  margin-top: -0px; }

.m-r-0 {
  margin-right: 0px; }

.m-r-n-0 {
  margin-right: -0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-b-n-0 {
  margin-bottom: -0px; }

.p-4 {
  padding: 4px; }

.p-t-4 {
  padding-top: 4px; }

.p-l-4 {
  padding-left: 4px; }

.p-r-4 {
  padding-right: 4px; }

.p-b-4 {
  padding-bottom: 4px; }

.m-4 {
  margin: 4px; }

.m-l-4 {
  margin-left: 4px; }

.m-l-n-4 {
  margin-left: -4px; }

.m-t-4 {
  margin-top: 4px; }

.m-t-n-4 {
  margin-top: -4px; }

.m-r-4 {
  margin-right: 4px; }

.m-r-n-4 {
  margin-right: -4px; }

.m-b-4 {
  margin-bottom: 4px; }

.m-b-n-4 {
  margin-bottom: -4px; }

.p-5 {
  padding: 5px; }

.p-t-5 {
  padding-top: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.m-5 {
  margin: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-l-n-5 {
  margin-left: -5px; }

.m-t-5 {
  margin-top: 5px; }

.m-t-n-5 {
  margin-top: -5px; }

.m-r-5 {
  margin-right: 5px; }

.m-r-n-5 {
  margin-right: -5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-b-n-5 {
  margin-bottom: -5px; }

.p-6 {
  padding: 6px; }

.p-t-6 {
  padding-top: 6px; }

.p-l-6 {
  padding-left: 6px; }

.p-r-6 {
  padding-right: 6px; }

.p-b-6 {
  padding-bottom: 6px; }

.m-6 {
  margin: 6px; }

.m-l-6 {
  margin-left: 6px; }

.m-l-n-6 {
  margin-left: -6px; }

.m-t-6 {
  margin-top: 6px; }

.m-t-n-6 {
  margin-top: -6px; }

.m-r-6 {
  margin-right: 6px; }

.m-r-n-6 {
  margin-right: -6px; }

.m-b-6 {
  margin-bottom: 6px; }

.m-b-n-6 {
  margin-bottom: -6px; }

.p-8 {
  padding: 8px; }

.p-t-8 {
  padding-top: 8px; }

.p-l-8 {
  padding-left: 8px; }

.p-r-8 {
  padding-right: 8px; }

.p-b-8 {
  padding-bottom: 8px; }

.m-8 {
  margin: 8px; }

.m-l-8 {
  margin-left: 8px; }

.m-l-n-8 {
  margin-left: -8px; }

.m-t-8 {
  margin-top: 8px; }

.m-t-n-8 {
  margin-top: -8px; }

.m-r-8 {
  margin-right: 8px; }

.m-r-n-8 {
  margin-right: -8px; }

.m-b-8 {
  margin-bottom: 8px; }

.m-b-n-8 {
  margin-bottom: -8px; }

.p-10 {
  padding: 10px; }

.p-t-10 {
  padding-top: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.m-10 {
  margin: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-l-n-10 {
  margin-left: -10px; }

.m-t-10 {
  margin-top: 10px; }

.m-t-n-10 {
  margin-top: -10px; }

.m-r-10 {
  margin-right: 10px; }

.m-r-n-10 {
  margin-right: -10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-b-n-10 {
  margin-bottom: -10px; }

.p-12 {
  padding: 12px; }

.p-t-12 {
  padding-top: 12px; }

.p-l-12 {
  padding-left: 12px; }

.p-r-12 {
  padding-right: 12px; }

.p-b-12 {
  padding-bottom: 12px; }

.m-12 {
  margin: 12px; }

.m-l-12 {
  margin-left: 12px; }

.m-l-n-12 {
  margin-left: -12px; }

.m-t-12 {
  margin-top: 12px; }

.m-t-n-12 {
  margin-top: -12px; }

.m-r-12 {
  margin-right: 12px; }

.m-r-n-12 {
  margin-right: -12px; }

.m-b-12 {
  margin-bottom: 12px; }

.m-b-n-12 {
  margin-bottom: -12px; }

.p-15 {
  padding: 15px; }

.p-t-15 {
  padding-top: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.m-15 {
  margin: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-l-n-15 {
  margin-left: -15px; }

.m-t-15 {
  margin-top: 15px; }

.m-t-n-15 {
  margin-top: -15px; }

.m-r-15 {
  margin-right: 15px; }

.m-r-n-15 {
  margin-right: -15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-b-n-15 {
  margin-bottom: -15px; }

.p-16 {
  padding: 16px; }

.p-t-16 {
  padding-top: 16px; }

.p-l-16 {
  padding-left: 16px; }

.p-r-16 {
  padding-right: 16px; }

.p-b-16 {
  padding-bottom: 16px; }

.m-16 {
  margin: 16px; }

.m-l-16 {
  margin-left: 16px; }

.m-l-n-16 {
  margin-left: -16px; }

.m-t-16 {
  margin-top: 16px; }

.m-t-n-16 {
  margin-top: -16px; }

.m-r-16 {
  margin-right: 16px; }

.m-r-n-16 {
  margin-right: -16px; }

.m-b-16 {
  margin-bottom: 16px; }

.m-b-n-16 {
  margin-bottom: -16px; }

.p-20 {
  padding: 20px; }

.p-t-20 {
  padding-top: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.m-20 {
  margin: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-l-n-20 {
  margin-left: -20px; }

.m-t-20 {
  margin-top: 20px; }

.m-t-n-20 {
  margin-top: -20px; }

.m-r-20 {
  margin-right: 20px; }

.m-r-n-20 {
  margin-right: -20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-b-n-20 {
  margin-bottom: -20px; }

.p-22 {
  padding: 22px; }

.p-t-22 {
  padding-top: 22px; }

.p-l-22 {
  padding-left: 22px; }

.p-r-22 {
  padding-right: 22px; }

.p-b-22 {
  padding-bottom: 22px; }

.m-22 {
  margin: 22px; }

.m-l-22 {
  margin-left: 22px; }

.m-l-n-22 {
  margin-left: -22px; }

.m-t-22 {
  margin-top: 22px; }

.m-t-n-22 {
  margin-top: -22px; }

.m-r-22 {
  margin-right: 22px; }

.m-r-n-22 {
  margin-right: -22px; }

.m-b-22 {
  margin-bottom: 22px; }

.m-b-n-22 {
  margin-bottom: -22px; }

.p-24 {
  padding: 24px; }

.p-t-24 {
  padding-top: 24px; }

.p-l-24 {
  padding-left: 24px; }

.p-r-24 {
  padding-right: 24px; }

.p-b-24 {
  padding-bottom: 24px; }

.m-24 {
  margin: 24px; }

.m-l-24 {
  margin-left: 24px; }

.m-l-n-24 {
  margin-left: -24px; }

.m-t-24 {
  margin-top: 24px; }

.m-t-n-24 {
  margin-top: -24px; }

.m-r-24 {
  margin-right: 24px; }

.m-r-n-24 {
  margin-right: -24px; }

.m-b-24 {
  margin-bottom: 24px; }

.m-b-n-24 {
  margin-bottom: -24px; }

.p-25 {
  padding: 25px; }

.p-t-25 {
  padding-top: 25px; }

.p-l-25 {
  padding-left: 25px; }

.p-r-25 {
  padding-right: 25px; }

.p-b-25 {
  padding-bottom: 25px; }

.m-25 {
  margin: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-l-n-25 {
  margin-left: -25px; }

.m-t-25 {
  margin-top: 25px; }

.m-t-n-25 {
  margin-top: -25px; }

.m-r-25 {
  margin-right: 25px; }

.m-r-n-25 {
  margin-right: -25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-b-n-25 {
  margin-bottom: -25px; }

.p-30 {
  padding: 30px; }

.p-t-30 {
  padding-top: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-r-30 {
  padding-right: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.m-30 {
  margin: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-l-n-30 {
  margin-left: -30px; }

.m-t-30 {
  margin-top: 30px; }

.m-t-n-30 {
  margin-top: -30px; }

.m-r-30 {
  margin-right: 30px; }

.m-r-n-30 {
  margin-right: -30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-b-n-30 {
  margin-bottom: -30px; }

.p-32 {
  padding: 32px; }

.p-t-32 {
  padding-top: 32px; }

.p-l-32 {
  padding-left: 32px; }

.p-r-32 {
  padding-right: 32px; }

.p-b-32 {
  padding-bottom: 32px; }

.m-32 {
  margin: 32px; }

.m-l-32 {
  margin-left: 32px; }

.m-l-n-32 {
  margin-left: -32px; }

.m-t-32 {
  margin-top: 32px; }

.m-t-n-32 {
  margin-top: -32px; }

.m-r-32 {
  margin-right: 32px; }

.m-r-n-32 {
  margin-right: -32px; }

.m-b-32 {
  margin-bottom: 32px; }

.m-b-n-32 {
  margin-bottom: -32px; }

.p-40 {
  padding: 40px; }

.p-t-40 {
  padding-top: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-r-40 {
  padding-right: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.m-40 {
  margin: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-l-n-40 {
  margin-left: -40px; }

.m-t-40 {
  margin-top: 40px; }

.m-t-n-40 {
  margin-top: -40px; }

.m-r-40 {
  margin-right: 40px; }

.m-r-n-40 {
  margin-right: -40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-b-n-40 {
  margin-bottom: -40px; }

.p-44 {
  padding: 44px; }

.p-t-44 {
  padding-top: 44px; }

.p-l-44 {
  padding-left: 44px; }

.p-r-44 {
  padding-right: 44px; }

.p-b-44 {
  padding-bottom: 44px; }

.m-44 {
  margin: 44px; }

.m-l-44 {
  margin-left: 44px; }

.m-l-n-44 {
  margin-left: -44px; }

.m-t-44 {
  margin-top: 44px; }

.m-t-n-44 {
  margin-top: -44px; }

.m-r-44 {
  margin-right: 44px; }

.m-r-n-44 {
  margin-right: -44px; }

.m-b-44 {
  margin-bottom: 44px; }

.m-b-n-44 {
  margin-bottom: -44px; }

.p-50 {
  padding: 50px; }

.p-t-50 {
  padding-top: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-r-50 {
  padding-right: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.m-50 {
  margin: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-l-n-50 {
  margin-left: -50px; }

.m-t-50 {
  margin-top: 50px; }

.m-t-n-50 {
  margin-top: -50px; }

.m-r-50 {
  margin-right: 50px; }

.m-r-n-50 {
  margin-right: -50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-b-n-50 {
  margin-bottom: -50px; }

.p-52 {
  padding: 52px; }

.p-t-52 {
  padding-top: 52px; }

.p-l-52 {
  padding-left: 52px; }

.p-r-52 {
  padding-right: 52px; }

.p-b-52 {
  padding-bottom: 52px; }

.m-52 {
  margin: 52px; }

.m-l-52 {
  margin-left: 52px; }

.m-l-n-52 {
  margin-left: -52px; }

.m-t-52 {
  margin-top: 52px; }

.m-t-n-52 {
  margin-top: -52px; }

.m-r-52 {
  margin-right: 52px; }

.m-r-n-52 {
  margin-right: -52px; }

.m-b-52 {
  margin-bottom: 52px; }

.m-b-n-52 {
  margin-bottom: -52px; }

.p-60 {
  padding: 60px; }

.p-t-60 {
  padding-top: 60px; }

.p-l-60 {
  padding-left: 60px; }

.p-r-60 {
  padding-right: 60px; }

.p-b-60 {
  padding-bottom: 60px; }

.m-60 {
  margin: 60px; }

.m-l-60 {
  margin-left: 60px; }

.m-l-n-60 {
  margin-left: -60px; }

.m-t-60 {
  margin-top: 60px; }

.m-t-n-60 {
  margin-top: -60px; }

.m-r-60 {
  margin-right: 60px; }

.m-r-n-60 {
  margin-right: -60px; }

.m-b-60 {
  margin-bottom: 60px; }

.m-b-n-60 {
  margin-bottom: -60px; }

.p-80 {
  padding: 80px; }

.p-t-80 {
  padding-top: 80px; }

.p-l-80 {
  padding-left: 80px; }

.p-r-80 {
  padding-right: 80px; }

.p-b-80 {
  padding-bottom: 80px; }

.m-80 {
  margin: 80px; }

.m-l-80 {
  margin-left: 80px; }

.m-l-n-80 {
  margin-left: -80px; }

.m-t-80 {
  margin-top: 80px; }

.m-t-n-80 {
  margin-top: -80px; }

.m-r-80 {
  margin-right: 80px; }

.m-r-n-80 {
  margin-right: -80px; }

.m-b-80 {
  margin-bottom: 80px; }

.m-b-n-80 {
  margin-bottom: -80px; }

.inline {
  display: inline-block; }

.inline-only {
  display: inline; }

.table-cell {
  display: table-cell; }

/*
* PROGRESS BAR
*/
.progress-container > div {
  margin-bottom: 10px; }

.progress-container .progress {
  background-color: #DBDBDB;
  height: 2px; }
  .progress-container .progress.md {
    height: 4px; }

/*
* BACKGROUND COLOR
*/
.bg-success {
  background-color: #7C9A76; }

.bg-danger {
  background-color: #EA4D4D; }

.bg-info {
  background-color: #2F80ED; }

.bg-header-gray {
  background-color: #F1F1F1; }

.bg-body {
  background-color: #F2F2F2; }

.bg-white {
  background-color: #FFFFFF; }

.bg-light-blue {
  background-color: #BDE6EC; }

.bg-purple {
  background-color: #564FDB; }

.bg-dark {
  background-color: #333333; }

.success {
  background-color: #7C9A76; }

.danger {
  background-color: #E36B6B; }

.info {
  background-color: #6472C7; }

.box-shadow {
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.19), 1px 8px 20px 4px rgba(169, 165, 165, 0.12); }

.line-height-sm {
  line-height: 1.2; }

.line-height-md {
  line-height: 1.4; }

.line-height-l {
  line-height: 2.4; }

.line-height-1 {
  line-height: 1; }

.full-width {
  width: 100%; }

.max-width {
  max-width: 100%; }

.bg-color-transparent {
  background-color: transparent; }

.input-thankyou {
  width: 100%;
  height: 35px;
  border-radius: 3.5px;
  border: 1px solid #333333;
  padding: 5px 10px;
  font-size: 16px; }

.input-thankyou::-webkit-input-placeholder {
  color: #B7B7B7 !important; }

/* WebKit, Blink, Edge */
.input-thankyou:-moz-placeholder {
  color: #B7B7B7 !important; }

/* Mozilla Firefox 4 to 18 */
.input-thankyou::-moz-placeholder {
  color: #B7B7B7 !important; }

/* Mozilla Firefox 19+ */
.input-thankyou:-ms-input-placeholder {
  color: #B7B7B7 !important; }

/* Internet Explorer 10-11 */
.input-thankyou::-ms-input-placeholder {
  color: #B7B7B7 !important; }

/* Microsoft Edge */
.input-thankyou-link {
  width: 86%;
  font-size: 13px;
  height: 35px;
  font-weight: bold;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #333333;
  padding: 5px 10px; }

.div-thankyou {
  padding-bottom: 10px; }

.button-save {
  background-color: #564fdb;
  border: 1px solid #564fdb;
  border-radius: 5px;
  color: #fff;
  min-width: 106px;
  height: 37px;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 20px; }

.button-copy {
  background-color: #564fdb;
  border: 1px solid #564fdb;
  border-radius: 0px 5px 5px 0px;
  color: #fff;
  width: 100px;
  min-width: 60px;
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  vertical-align: top; }

.form-thankyou {
  display: contents; }

.input-error {
  border-color: #EA4D4D;
  color: #EA4D4D; }

.profilelink {
  font-size: 15px;
  color: #333333; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* MIXINS
*
*/
/*
* CHECKBOX
*/
.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #564FDB;
  border-radius: 3px;
  background-color: transparent;
  padding: 0;
  margin-right: 10px; }
  .checkbox.checked {
    background-color: #564FDB;
    border-color: #564FDB; }
    .checkbox.checked:after {
      font-family: 'fontAwesome';
      content: "\f00c";
      color: #FFFFFF;
      font-size: 11px;
      position: relative;
      top: -6px; }
  .checkbox:hover {
    border: 1px solid #564FDB; }

/*
* LISTS
*/
.label__list {
  display: inline-block;
  cursor: pointer; }

.img__question, .img__answer {
  width: auto;
  max-width: 400px;
  max-height: 300px; }

.img__question__ftq {
  width: auto;
  max-width: 400px;
  max-height: 100%; }
  @media screen and (max-height: 2000px) {
    .img__question__ftq {
      max-height: 100% !important; } }

.img-container-ftq {
  text-align: center;
  margin-bottom: 30px; }

.img-box {
  position: relative; }

.img-box:hover .img-ftq {
  opacity: 1; }

.img-box:hover .img-ftq-hover {
  opacity: 1; }

.img-ftq {
  display: inline-block;
  opacity: 1;
  transition: .5s ease;
  backface-visibility: hidden; }

.img-ftq-hover {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center; }

.question-ftq-container {
  padding-bottom: 0px !important; }

.label__answer {
  flex: 2 0px; }

.list {
  width: 30px;
  height: 30px;
  border: 2px solid #564FDB;
  border-radius: 50px;
  background-color: transparent;
  padding: 0;
  margin-right: 10px;
  color: #564FDB;
  font-weight: bold; }
  .list:hover, .list.checked {
    background-color: #564FDB;
    border-color: #564FDB;
    color: #FFFFFF; }

/*
* HEADER
*/
header {
  background-color: #FFFFFF;
  color: #554AE4;
  padding: 32px; }
  header small {
    border-left: 1px solid #FFFFFF;
    padding: 0 10px; }

.header__session {
  padding: 12px 32px; }
  .header__session.large {
    padding: 21px 32px; }
  .header__session.small {
    padding: 10px 10px; }

@media screen and (min-height: 1px) and (max-height: 750px) and (orientation: landscape) {
  .header__session {
    padding: 10px 32px; }
    .header__session.large {
      padding: 10px 32px; } }

.header__unsupported {
  padding: 30px 32px; }
  .header__unsupported.large {
    padding: 30px 32px; }

.navbar-brand {
  max-height: 64px; }

/*
* CARDS, PANEL, INDICATOR
*/
.card {
  background: #FFFFFF;
  border-radius: 3px;
  padding: 12px 24px;
  border: 0; }
  .card .card-title {
    margin-bottom: 16px; }
  .card .card-text {
    max-width: 500px;
    margin: 0 auto; }
  .card .card-text-sound-check {
    font-size: 14px;
    max-width: 100%;
    margin: 0 auto; }
  .card .card-footer {
    border-top: 1px solid #BDBDBD;
    background: transparent; }
  .card .img-question {
    max-width: 100% !important;
    margin: 16px 0; }
  .card img {
    max-width: 100%;
    margin: 16px 0; }
  .card__basic {
    padding: 32px;
    margin-top: 44px;
    text-align: center; }
  .card__rating {
    padding: 40px 125px;
    margin-top: 44px;
    text-align: center; }
    .card__rating h4 {
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;
      padding: 0 20px;
      margin-bottom: 0; }
      .card__rating h4.bold {
        font-weight: bold; }
    .card__rating .div-not {
      font-size: 12px;
      line-height: 15px;
      color: #E33434;
      width: 75px; }
    .card__rating .div-like {
      font-size: 12px;
      line-height: 15px;
      color: #70C780; }
    .card__rating .margin-left-min {
      margin-left: -12px; }
    .card__rating .margin-right-min {
      margin-right: -3px; }
    .card__rating .row-textarea {
      margin-left: -30px;
      margin-right: -30px; }
    .card__rating textarea::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #AAAAAA;
      font-size: 14px; }
    .card__rating textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #AAAAAA;
      font-size: 14px; }
    .card__rating textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #AAAAAA;
      font-size: 14px; }
    .card__rating textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #AAAAAA;
      font-size: 14px; }
    .card__rating textarea::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #AAAAAA;
      font-size: 14px; }
    .card__rating textarea::placeholder {
      /* Most modern browsers support this now. */
      color: #AAAAAA;
      font-size: 14px; }
  .card__sound {
    padding: 20px;
    margin-top: 44px;
    text-align: center; }
  .card__title {
    padding: 20px;
    margin-top: 20px;
    text-align: left; }
  .card__desc {
    padding: 20px;
    margin-top: 20px;
    text-align: left; }
  .card__price {
    padding: 30px;
    margin-top: 20px;
    text-align: left; }
    .card__price .rupiah {
      color: #554AE4;
      font-weight: bold;
      font-size: 24px; }
  .card__custom {
    padding: 0;
    margin-top: 44px;
    text-align: center; }
    .card__custom .card-body {
      padding: 36px 24px; }
    .card__custom .card-footer {
      padding: 24px; }
  .card__material {
    padding: 13px 24px;
    margin-top: 24px;
    text-align: left; }
    .card__material .card-body {
      padding: 36px 24px; }
    .card__material .card-footer {
      padding: 24px; }
  .card__verify {
    padding: 0;
    margin-top: 44px;
    text-align: center; }
    .card__verify .card-body {
      padding: 36px 24px; }
      .card__verify .card-body img {
        margin: 10px 0; }
      .card__verify .card-body .spinning {
        -webkit-animation: spinning 2s linear infinite;
        /* Safari */
        animation: spinning 2s linear infinite; }

@-webkit-keyframes spinning {
  0% {
    -webkit-transform: rotate(360deg); }
  100% {
    -webkit-transform: rotate(0deg); } }

@keyframes spinning {
  0% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(0deg); } }
      .card__verify .card-body hr {
        width: 60%; }
      .card__verify .card-body .q-container .div-num {
        width: 4%;
        font-weight: bold; }
      .card__verify .card-body .q-container .div-q {
        width: 90%; }
      .card__verify .card-body .q-container .div-ico {
        width: 6%; }
      .card__verify .card-body .info-container {
        max-width: 500px;
        margin: 10px auto; }
        .card__verify .card-body .info-container .div-ico {
          width: 8%; }
        .card__verify .card-body .info-container .div-info {
          width: 92%; }
      .card__verify .card-body .card-title {
        font-size: 16px;
        color: #313131; }
      .card__verify .card-body .card-text {
        font-size: 15px; }
      .card__verify .card-body .bold {
        font-size: 16px; }
    .card__verify .card-footer {
      padding: 24px; }
  .card__list {
    padding: 28px 32px;
    margin-top: 44px;
    text-align: left; }
  .card__note {
    text-align: center;
    min-height: 400px; }
  .card--loading {
    min-height: 400px; }

.panel {
  background: #FFFFFF;
  border-radius: 4px;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.19), 1px 8px 20px 4px rgba(169, 165, 165, 0.12);
  padding: 12px 24px; }

.indicator {
  width: 100%;
  height: 48px;
  color: #FFFFFF;
  padding: 14px 24px; }

.indicator-div {
  width: 100%; }

.media {
  padding: 16px 0; }
  .media:not(:last-child) {
    border-bottom: 1px solid #DCDCDC; }

/*
* MODALS
*/
.modal-body {
  padding: 32px; }

.modal-dialog {
  background-color: #FFFFFF;
  border-radius: 5px;
  text-align: center; }

.modal-welcome-video {
  width: 1500px;
  height: 800px; }

.modal-img-ftq {
  font-size: 12px; }

.modal-img-ftq > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px; }

.modal-img-ftq > .content {
  width: 100%;
  padding: 10px 5px; }

.modal-img-ftq > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center; }

.modal-img-ftq > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  opacity: 1; }

.modal-img-ftq > img {
  margin: 0;
  max-width: 50vw;
  max-height: 80vh; }

/*
* TEXTAREA
*/
.ftq-textarea {
  width: 100%;
  min-height: 166px;
  padding: 12px 14px 12px 14px;
  border-radius: 4px;
  resize: none;
  overflow: hidden;
  font-size: 17px;
  font-family: 'Open Sans';
  border: 1px solid rgba(51, 51, 51, 0.1); }
  @media screen and (max-height: 768px) {
    .ftq-textarea {
      min-height: 130px !important; } }

.ftq-textarea-oninput {
  border: 1px solid rgba(51, 51, 51, 0.8) !important;
  outline-offset: 0px !important;
  outline: none !important; }

.ftq-textarea:disabled {
  background: #dddddd;
  cursor: not-allowed; }

.div-form-textarea {
  padding-left: 20px;
  padding-right: 20px; }

.div-text-maxwords {
  font-size: 14px;
  color: #000000;
  font-family: 'Open Sans';
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px; }

.div-text-countwords {
  font-size: 14px;
  color: #737373;
  font-family: 'Open Sans';
  padding-right: 25px;
  padding-left: 25px;
  float: right;
  text-align: right;
  margin-bottom: 50px; }

/*
* DOTS
*/
.dots {
  -webkit-animation: pulse 1.5s linear infinite;
  -moz-animation: pulse 1.5s linear infinite;
  border-image: initial; }
  .dots--red:before {
    content: ' \25CF';
    font-size: 25px;
    color: #D95F5F;
    display: inline-block; }
  .dots--grey:before {
    content: ' \25CF';
    font-size: 25px;
    color: #C3C3C3;
    display: inline-block; }

.CircularProgressbar {
  width: 100%; }
  .CircularProgressbar .CircularProgressbar-path {
    stroke: #3e98c7;
    stroke-linecap: square;
    transition: stroke-dashoffset 0.5s ease 0s; }
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #d6d6d6; }
  .CircularProgressbar .CircularProgressbar-text {
    fill: #3e98c7;
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle; }
  .CircularProgressbar .CircularProgressbar-background {
    fill: #d6d6d6; }
  .CircularProgressbar.circular-counter .CircularProgressbar-trail {
    stroke: #FFFFFF; }
  .CircularProgressbar.circular-counter .CircularProgressbar-background {
    fill: #EA4D4D; }
  .CircularProgressbar.circular-counter .CircularProgressbar-path {
    stroke: #EA4D4D; }
  .CircularProgressbar.circular-counter .CircularProgressbar-text {
    fill: #FFFFFF;
    font-size: 42px;
    font-family: 'Open Sans'; }
  .CircularProgressbar.circular-counter.start .CircularProgressbar-background {
    fill: transparent; }
  .CircularProgressbar.circular-counter.start .CircularProgressbar-text {
    font-size: 24px; }
  .CircularProgressbar.circular-counter.start .CircularProgressbar-path {
    stroke: #FFFFFF; }
  .CircularProgressbar.circular-counter.after-three .CircularProgressbar-background {
    fill: transparent; }
  .CircularProgressbar.circular-counter.after-three .CircularProgressbar-text {
    font-size: 24px; }
  .CircularProgressbar.circular-counter.after-three .CircularProgressbar-path {
    stroke: #EA4D4D; }
  .CircularProgressbar.circular-counter.finish .CircularProgressbar-background {
    fill: #EA4D4D; }
  .CircularProgressbar.circular-counter.finish .CircularProgressbar-text {
    font-size: 24px; }
  .CircularProgressbar.circular-counter.finish .CircularProgressbar-path {
    stroke: #EA4D4D; }

.transparent-fill {
  fill: transparent; }

/*
* AUDIO AND VOLUME BAR
*/
.audio-test .audio-container .div-sound-input {
  background: #E5E5E5;
  border-radius: 5px;
  width: 350px;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px; }
  .audio-test .audio-container .div-sound-input i {
    font-size: 12px;
    color: #666666; }
    .audio-test .audio-container .div-sound-input i.fa-play {
      color: #564FDB;
      font-size: 23px; }
    .audio-test .audio-container .div-sound-input i.fa-stop {
      color: #564FDB;
      font-size: 23px; }
    .audio-test .audio-container .div-sound-input i.recording {
      color: #E33434; }
  .audio-test .audio-container .div-sound-input .div-label-input-level {
    font-size: 12px;
    font-weight: bold;
    margin-right: 10px;
    color: #999999;
    position: relative;
    display: inline-block; }

.audio-test .div-button-record {
  margin-top: 20px; }

.audio-test .div-button-yesno {
  margin: 0 auto;
  margin-top: 20px;
  width: 350px; }

.volumes {
  position: relative;
  background: #333333;
  display: inline-block; }

.volume-bar {
  transition: all 1s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background: #3DB223; }

.volume-bar-container {
  background: rgba(219, 219, 219, 0.3);
  margin: 0 auto;
  position: relative;
  height: 60px;
  width: 8px; }
  .volume-bar-container:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: " ";
    background: linear-gradient(to top, transparent 0, transparent 7%, #333333 7%, #333333 10%, transparent 10%, transparent 17%, #333333 17%, #333333 20%, transparent 20%, transparent 27%, #333333 27%, #333333 30%, transparent 30%, transparent 37%, #333333 37%, #333333 40%, transparent 40%, transparent 47%, #333333 47%, #333333 50%, transparent 50%, transparent 57%, #333333 57%, #333333 60%, transparent 60%, transparent 67%, #333333 67%, #333333 70%, transparent 70%, transparent 77%, #333333 77%, #333333 80%, transparent 80%, transparent 87%, #333333 87%, #333333 90%, transparent 90%, transparent 100%); }

.volume-horizontal {
  position: relative;
  display: inline-block; }

.volume-meter {
  transition: all 1s;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background: #3DB223; }

.volume-meter-container {
  background: #666666;
  margin: 0 auto;
  position: relative;
  height: 17px;
  width: 120px; }
  .volume-meter-container:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: " ";
    background: linear-gradient(to right, transparent 0, transparent 7%, #E5E5E5 7%, #E5E5E5 10%, transparent 10%, transparent 17%, #E5E5E5 17%, #E5E5E5 20%, transparent 20%, transparent 27%, #E5E5E5 27%, #E5E5E5 30%, transparent 30%, transparent 37%, #E5E5E5 37%, #E5E5E5 40%, transparent 40%, transparent 47%, #E5E5E5 47%, #E5E5E5 50%, transparent 50%, transparent 57%, #E5E5E5 57%, #E5E5E5 60%, transparent 60%, transparent 67%, #E5E5E5 67%, #E5E5E5 70%, transparent 70%, transparent 77%, #E5E5E5 77%, #E5E5E5 80%, transparent 80%, transparent 87%, #E5E5E5 87%, #E5E5E5 90%, transparent 90%, transparent 100%); }

/*
* Alert
*/
.alert--light .text {
  border: 1px solid #C8C8C8;
  background: rgba(255, 255, 255, 0.5); }

.alert--top {
  position: absolute;
  top: 0;
  width: 100%; }
  .alert--top .text {
    border-radius: 5px;
    padding: 9px 25px;
    min-width: 400px;
    margin: 10px auto;
    text-align: center; }

/*
* PANEL OPTION
*/
.panel-option {
  position: relative;
  max-width: 240px;
  overflow: hidden; }

.panel-option-list {
  max-width: 200px; }
  .panel-option-list .form-group {
    margin-bottom: 0; }

#next path {
  fill: #FFFFFF; }

#previous path {
  fill: #FFFFFF; }

.btn-slide-container {
  position: relative;
  max-width: 240px;
  height: 48px;
  overflow: hidden; }

.slider {
  transition: 1s;
  right: 0;
  position: relative;
  top: 0;
  height: 48px;
  right: -240px;
  width: 100%; }
  .slider.show {
    right: 0;
    transition: 1s; }

.slide-container {
  position: relative;
  min-height: 319px;
  height: 100%;
  overflow: hidden; }

.slide {
  width: 240px;
  position: relative;
  right: -303px;
  transition: 1s; }
  .slide.show {
    transition: 1s;
    right: 0; }

/*
* SIGN
*/
.sign {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #564FDB; }
  .sign.fullfill {
    background-color: #564FDB; }

/*
* BIG COUNTER
*/
.big-counter {
  position: absolute;
  margin: -4% auto;
  text-align: center;
  width: 100%; }
  .big-counter .text {
    font-size: 260px;
    color: rgba(255, 255, 255, 0.8); }

/*
* SIDEBAR
*/
#sidebar {
  width: 240px;
  position: absolute;
  right: 0;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out; }

/*
* LOADING
*/
.loading {
  display: inline-block; }
  .loading span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both; }
    .loading span:nth-child(2) {
      animation-delay: .2s; }
    .loading span:nth-child(3) {
      animation-delay: .3s; }
    .loading span:nth-child(4) {
      animation-delay: .4s; }

/*
* SLIDER
*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before {
    display: table;
    content: ''; }
  .slick-track:after {
    display: table;
    content: '';
    clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*
* ARROWS
*/
.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }

.slick-prev:hover, .slick-prev:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-next:hover, .slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev.slick-disabled, .slick-next.slick-disabled {
  background-image: none; }
  .slick-prev.slick-disabled:hover, .slick-prev.slick-disabled:focus, .slick-next.slick-disabled:hover, .slick-next.slick-disabled:focus {
    background-image: none; }

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -30px;
  z-index: 1;
  background-image: url(../../images/prev.png);
  position: absolute;
  width: 60px;
  height: 60px; }
  .slick-prev:hover, .slick-prev:focus {
    background-image: url(../../images/prev.png); }

.slick-next {
  right: -30px;
  z-index: 1;
  background-image: url(../../images/next.png);
  position: absolute;
  width: 60px;
  height: 60px; }
  .slick-next:hover, .slick-next:focus {
    background-image: url(../../images/next.png); }

/*
* SLICK
*/
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
      .slick-dots li button:hover:before, .slick-dots li button:focus:before {
        opacity: 1; }
      .slick-dots li button:before {
        font-family: 'FontAwesome';
        content: '\F10C';
        font-size: 14px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        text-align: center;
        color: #564FDB;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #564FDB;
      content: '\F111'; }

.boarding-item {
  background: #FFFFFF;
  position: relative;
  text-align: center;
  padding: 20px 20px 60px; }

.container-grey-not-interested {
  background: #F5F5F5;
  border-radius: 4px;
  padding: 24px; }
  .container-grey-not-interested .form-group {
    margin-bottom: 0.3rem; }
  .container-grey-not-interested .list {
    border: 2px solid transparent;
    margin-right: 0;
    width: 24px;
    height: 24px; }
    .container-grey-not-interested .list.checked, .container-grey-not-interested .list:hover, .container-grey-not-interested .list:active, .container-grey-not-interested .list:focus {
      border: 5px solid #FFFFFF; }
  .container-grey-not-interested textarea {
    border: 2px solid #E8E8E8;
    border-radius: 6px;
    resize: none; }

.container-not-interested .button__primary, .container-not-interested .button__secondary {
  border-radius: 4px;
  margin: 0 8px; }

.container-not-interested .button__primary {
  background-color: #165295; }

.container-not-interested .button__secondary {
  border-color: #165295;
  color: #165295; }

.wrapper-button-radio {
  border: 2px solid #CFCFCF;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  height: 28px; }
  .wrapper-button-radio.checked, .wrapper-button-radio:hover, .wrapper-button-radio:active, .wrapper-button-radio:focus {
    border: 2px solid #564FDB; }

.toggle-slider {
  left: -80px;
  position: absolute; }

.inline-form-group-questions {
  width: 20%;
  display: inline-block;
  padding-right: 10px; }

.border-top-question-mcq-all {
  border-top: 1px solid #DDDDDD; }

.div-vid-ins {
  text-align: center;
  margin: 30px auto;
  width: 85%; }
  .div-vid-ins video::-webkit-media-controls-timeline,
  .div-vid-ins video::-webkit-media-controls-volume-slider,
  .div-vid-ins video::-webkit-media-controls-mute-button,
  .div-vid-ins video::-webkit-media-controls-current-time-display,
  .div-vid-ins video::-webkit-media-controls-time-remaining-display,
  .div-vid-ins video::-webkit-media-controls-fullscreen-button {
    display: initial !important; }

.video-instruksi {
  max-width: 85%;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  border-radius: 5px; }

.footer-astrnt {
  height: 100px;
  background-color: black;
  font-size: 12px;
  color: white; }
  .footer-astrnt img {
    height: 26px;
    width: auto;
    margin: 0 10px; }

.btn-all-topic {
  cursor: pointer;
  color: #564FDB;
  display: inline-block; }
  .btn-all-topic p {
    font-weight: bold; }
  .btn-all-topic i {
    margin-left: 5px; }

.div-topic-list {
  margin: 15px 0;
  cursor: pointer;
  font-size: 20px;
  color: #707070; }
  .div-topic-list i {
    color: #564FDB; }
  .div-topic-list .first-div {
    width: 70%;
    display: inline-block; }
  .div-topic-list .last-div {
    width: 30%;
    display: inline-block;
    text-align: right; }

.div-ulasan {
  color: #707070; }
  .div-ulasan .div-rating {
    width: 15%;
    display: inline-block;
    font-weight: bold;
    vertical-align: top; }
    .div-ulasan .div-rating .kotak-rating {
      width: 80px;
      height: 80px;
      border-radius: 5px;
      background-color: #554AE4;
      color: white;
      font-size: 32px;
      font-weight: bold; }
  .div-ulasan .div-rating-mobile {
    display: flex;
    font-weight: bold;
    font-size: 18px;
    color: black;
    vertical-align: top;
    padding: 10px 15px;
    background-color: #F3F3F3;
    border-radius: 5px;
    margin-bottom: 15px; }
    .div-ulasan .div-rating-mobile .kotak-rating {
      width: 50px;
      height: 43px;
      border-radius: 7px;
      background-color: #554AE4;
      color: white;
      font-size: 21px;
      font-weight: bold; }
  .div-ulasan .div-rating-list {
    width: 85%;
    display: inline-block; }
    .div-ulasan .div-rating-list.no-data {
      background: #F7F7F7 0% 0% no-repeat padding-box;
      border-radius: 5px; }
    .div-ulasan .div-rating-list .list-rating {
      margin-bottom: 30px; }
      .div-ulasan .div-rating-list .list-rating.no-data {
        text-align: center;
        font: Regular 14px/21px Open Sans;
        letter-spacing: 0;
        color: #707070;
        opacity: 1; }
      .div-ulasan .div-rating-list .list-rating .rating-score {
        font-weight: bold;
        color: #554AE4;
        font-size: 14px; }
        .div-ulasan .div-rating-list .list-rating .rating-score span {
          font-size: 18px; }
    .div-ulasan .div-rating-list .div-lihat-semua {
      color: #564FDB;
      font-weight: bold;
      display: inline-block;
      cursor: pointer; }
      .div-ulasan .div-rating-list .div-lihat-semua i {
        font-size: 10px; }
    .div-ulasan .div-rating-list .lihat-banyak-container {
      text-align: center; }
    .div-ulasan .div-rating-list .btn-lihat-banyak {
      padding: 12px 20px;
      width: 242px;
      height: 48px;
      border: 1px solid #564FDB;
      color: #564FDB;
      font-weight: bold;
      font-size: 14px;
      display: inline-block;
      cursor: pointer; }
      .div-ulasan .div-rating-list .btn-lihat-banyak i {
        font-size: 16px;
        margin-left: 10px; }

.registration {
  padding: 50px;
  padding-top: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 10px #E5E5E5;
  margin-bottom: 20px; }

.login {
  padding: 50px;
  padding-top: 33px;
  padding-bottom: 26px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 10px #E5E5E5;
  margin-bottom: 20px; }

.vertical-form label, .vertical-form p {
  display: block; }

.vertical-form label {
  font-size: 12px;
  font-weight: bold;
  color: #707070;
  margin-bottom: 5px; }
  .vertical-form label b {
    font-weight: bold; }
  .vertical-form label a {
    color: #564FDB; }

.vertical-form input[type="text"], .vertical-form input[type="password"], .vertical-form textarea {
  font-size: 16px;
  padding: 13px 15px 15px 15px;
  display: block;
  width: 100%;
  color: #666666;
  border: 1px solid #999999;
  border-radius: 5px; }
  .vertical-form input[type="text"]:focus, .vertical-form input[type="password"]:focus, .vertical-form textarea:focus {
    outline: none;
    border: 1px solid #564FDB;
    box-shadow: 0 0 10px #564FDB; }

.vertical-form .form-group {
  margin-bottom: 12px; }
  .vertical-form .form-group .label-radio {
    font-size: 12px;
    font-weight: normal;
    color: #707070; }

.max-width-500 {
  max-width: 500px !important; }

.max-width-550 {
  max-width: 550px !important; }

.div-apply-now {
  height: 40px;
  margin-bottom: 20px;
  margin-left: -50px;
  margin-right: -48px; }
  .div-apply-now p {
    font-size: 24px !important;
    color: #564FDB;
    font-weight: bold !important;
    margin-bottom: 20px !important;
    margin-left: 20px !important; }
  .div-apply-now hr {
    border: 1px solid #564FDB;
    width: 100%;
    margin: auto; }

.main-gdpr-fluid {
  background-color: #999999;
  padding: 20px;
  border-radius: 5px;
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */
  /* When the checkbox is checked, add a blue background */
  /* Create the checkmark/indicator (hidden when not checked) */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */ }
  .main-gdpr-fluid.is-on {
    background-color: #564FDB; }
  .main-gdpr-fluid label {
    color: white;
    font-size: 12px;
    font-weight: normal; }
  .main-gdpr-fluid .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .main-gdpr-fluid .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .main-gdpr-fluid .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: #eee; }
  .main-gdpr-fluid .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc; }
  .main-gdpr-fluid .checkbox-container input:checked ~ .checkmark {
    background-color: #FFF; }
  .main-gdpr-fluid .checkmark:after {
    content: "";
    position: absolute;
    display: none; }
  .main-gdpr-fluid .checkbox-container input:checked ~ .checkmark:after {
    display: block; }
  .main-gdpr-fluid .checkbox-container .checkmark:after {
    left: 10px;
    top: 4px;
    width: 7px;
    height: 15px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-color: #564fdb;
    border-style: solid;
    border-image: initial; }

.div-support-materials {
  margin-top: 15px; }
  .div-support-materials .empty-file {
    border: 1px dashed #BDBDBD;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px 12px; }
  .div-support-materials .ada-file {
    font-size: 14px;
    margin-bottom: 15px; }
    .div-support-materials .ada-file span:first-of-type {
      max-width: 87%;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis; }
    .div-support-materials .ada-file .titik-titik {
      opacity: 0.1;
      border-bottom: 1px dashed #000000;
      flex-grow: 1;
      margin-bottom: -8px;
      margin-left: 5px;
      margin-right: 5px; }
  .div-support-materials .download-file {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-left: -24px;
    margin-right: -24px;
    padding-top: 10px; }
    .div-support-materials .download-file a {
      font-size: 14px;
      font-weight: bold;
      color: #5A52D3; }
      .div-support-materials .download-file a i {
        margin-right: 5px; }

@media screen and (max-width: 767px) {
  .ada-file span:first-of-type {
    max-width: 72% !important; } }

@media screen and (max-width: 375px) and (orientation: portrait) {
  .ada-file span:first-of-type {
    max-width: 70% !important; } }

@media screen and (max-width: 360px) and (orientation: portrait) {
  .ada-file span:first-of-type {
    max-width: 69% !important; } }

@media screen and (max-width: 320px) and (orientation: portrait) {
  .ada-file span:first-of-type {
    max-width: 65% !important; } }

.div-separator-or {
  width: 100%;
  height: 13px;
  border-bottom: 1px solid #CCCCCC;
  text-align: center;
  margin-top: 14px; }

.span-separator-or {
  font-size: 16px;
  background-color: white;
  color: #707070;
  font-weight: normal;
  padding: 0 10px; }

.label-ask {
  font-size: 16px;
  color: #707070;
  font-weight: bold; }

.error {
  color: #EC3333;
  font-size: 12px; }

input.error {
  border: 1px solid #EC3333 !important;
  color: #EC3333; }

.dropdown-menu {
  top: 20px !important;
  min-width: 250px;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 6px;
  border: 0; }
  .dropdown-menu .dropdown-item:hover {
    background-color: transparent; }
  .dropdown-menu a, .dropdown-menu span {
    color: #554AE4;
    font-size: 18px; }
    .dropdown-menu a:hover, .dropdown-menu span:hover {
      color: #554AE4; }

.required-star {
  color: #FF0000;
  margin-left: 3px; }

.tooltiptext {
  visibility: hidden;
  width: 270px;
  background-color: white;
  color: #404040;
  line-height: 18px;
  text-align: left;
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  z-index: 1;
  top: 25px;
  right: 103%;
  box-shadow: 0px 0px 8px 4px rgba(207, 207, 207, 0.32); }

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 100%;
  margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent white; }

.input-tool {
  position: relative; }
  .input-tool:active .tooltiptext, .input-tool:hover .tooltiptext, .input-tool:focus .tooltiptext {
    visibility: visible; }

.pass-req {
  display: block;
  color: #CFCFCF;
  margin-top: 8px; }

.pass-req.active {
  color: #2CB582 !important; }

.fa-check-circle {
  margin-right: 7px;
  font-size: 13px; }

.pass-req-mobile {
  color: #808080;
  font-size: 12px;
  margin-bottom: 5px;
  display: none; }

.jwplayer {
  display: inline-block !important;
  width: 100% !important; }

.jw-video {
  -moz-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1); }

@media screen and (max-width: 767px) and (orientation: portrait) {
  .tooltiptext {
    display: none; }
  .pass-req-mobile {
    display: inline-block; } }

/*
*
* ASTRONAUT WEB APP
* PAGES
*
*/
/*
*
* ASTRONAUT PUBLIC WEB APP
* VARIABLES
*
*/
.text-white {
  color: #FFFFFF; }

.text-purple {
  color: #564FDB; }

.text-link {
  color: #132ED5; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* MIXINS
*
*/
/*
*
* ASTRONAUT PUBLIC WEB APP
* BASE
*
*/
/*
*
* ASTRONAUT PUBLIC WEB APP
* VARIABLES
*
*/
.text-white {
  color: #FFFFFF; }

.text-purple {
  color: #564FDB; }

.text-link {
  color: #132ED5; }

/*
*
* ASTRONAUT PUBLIC WEB APP
* MIXINS
*
*/
html, body {
  width: 100%;
  height: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.57142857;
  background: #F2F2F2;
  color: #333333; }

a {
  cursor: pointer;
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: none; }

button {
  border: 0; }

button, button:focus, .btn, .btn:focus {
  outline: none !important;
  outline: 0;
  cursor: pointer; }

.hide {
  display: none !important; }

.hidden {
  display: none; }

.no-margin {
  margin: 0; }

.no-rounded-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.fixed {
  position: fixed; }

/*
* FLEX CONTAINER
*/
.flex-container, .vertical-item--center, .panel, .vertical-item--top, .vertical-item--justify, .horizontal-item--around, .horizontal-item--between, .horizontal-item--evenly, .horizontal-item--center, .indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap; }

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-between, .horizontal-item--between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.flex-evenly, .horizontal-item--evenly {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-evenly; }

.flex-start, .vertical-item--justify {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

.flex-around, .horizontal-item--around {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.flex-center, .horizontal-item--center, .card__note, .card--loading {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.align-items-center, .vertical-item--center, .panel, .horizontal-item--around, .horizontal-item--between, .horizontal-item--evenly, .horizontal-item--center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.align-items-start, .vertical-item--top {
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start; }

.align-items-end {
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end; }

.flex-row {
  flex-direction: row; }

.flex-grow {
  flex-grow: 1;
  flex: 1; }

.flex-column {
  flex-direction: column; }

.navbar {
  background: #FFFFFF;
  border-bottom: 1px solid #3E3C82; }

.navbar-header {
  display: block;
  opacity: 1;
  padding: 21px 0; }
  .navbar-header img {
    text-align: left;
    margin: 0;
    max-height: 37px; }

.block {
  display: block; }

.center {
  margin: 0 auto;
  text-align: center; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.valign-middle {
  vertical-align: middle; }

.valign-bottom {
  vertical-align: bottom; }

.valign-top {
  vertical-align: top; }

.max-w-100 {
  max-width: 100%; }

.w-100 {
  width: 100%; }

.float-right {
  float: right; }

.color-666 {
  color: #666666; }

.color-999 {
  color: #999999; }

/*
* MARGINS & PADDINGS
*/
.p-0 {
  padding: 0px; }

.p-t-0 {
  padding-top: 0px; }

.p-l-0 {
  padding-left: 0px; }

.p-r-0 {
  padding-right: 0px; }

.p-b-0 {
  padding-bottom: 0px; }

.m-0 {
  margin: 0px; }

.m-l-0 {
  margin-left: 0px; }

.m-l-n-0 {
  margin-left: -0px; }

.m-t-0 {
  margin-top: 0px; }

.m-t-n-0 {
  margin-top: -0px; }

.m-r-0 {
  margin-right: 0px; }

.m-r-n-0 {
  margin-right: -0px; }

.m-b-0 {
  margin-bottom: 0px; }

.m-b-n-0 {
  margin-bottom: -0px; }

.p-4 {
  padding: 4px; }

.p-t-4 {
  padding-top: 4px; }

.p-l-4 {
  padding-left: 4px; }

.p-r-4 {
  padding-right: 4px; }

.p-b-4 {
  padding-bottom: 4px; }

.m-4 {
  margin: 4px; }

.m-l-4 {
  margin-left: 4px; }

.m-l-n-4 {
  margin-left: -4px; }

.m-t-4 {
  margin-top: 4px; }

.m-t-n-4 {
  margin-top: -4px; }

.m-r-4 {
  margin-right: 4px; }

.m-r-n-4 {
  margin-right: -4px; }

.m-b-4 {
  margin-bottom: 4px; }

.m-b-n-4 {
  margin-bottom: -4px; }

.p-5 {
  padding: 5px; }

.p-t-5 {
  padding-top: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.m-5 {
  margin: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-l-n-5 {
  margin-left: -5px; }

.m-t-5 {
  margin-top: 5px; }

.m-t-n-5 {
  margin-top: -5px; }

.m-r-5 {
  margin-right: 5px; }

.m-r-n-5 {
  margin-right: -5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-b-n-5 {
  margin-bottom: -5px; }

.p-6 {
  padding: 6px; }

.p-t-6 {
  padding-top: 6px; }

.p-l-6 {
  padding-left: 6px; }

.p-r-6 {
  padding-right: 6px; }

.p-b-6 {
  padding-bottom: 6px; }

.m-6 {
  margin: 6px; }

.m-l-6 {
  margin-left: 6px; }

.m-l-n-6 {
  margin-left: -6px; }

.m-t-6 {
  margin-top: 6px; }

.m-t-n-6 {
  margin-top: -6px; }

.m-r-6 {
  margin-right: 6px; }

.m-r-n-6 {
  margin-right: -6px; }

.m-b-6 {
  margin-bottom: 6px; }

.m-b-n-6 {
  margin-bottom: -6px; }

.p-8 {
  padding: 8px; }

.p-t-8 {
  padding-top: 8px; }

.p-l-8 {
  padding-left: 8px; }

.p-r-8 {
  padding-right: 8px; }

.p-b-8 {
  padding-bottom: 8px; }

.m-8 {
  margin: 8px; }

.m-l-8 {
  margin-left: 8px; }

.m-l-n-8 {
  margin-left: -8px; }

.m-t-8 {
  margin-top: 8px; }

.m-t-n-8 {
  margin-top: -8px; }

.m-r-8 {
  margin-right: 8px; }

.m-r-n-8 {
  margin-right: -8px; }

.m-b-8 {
  margin-bottom: 8px; }

.m-b-n-8 {
  margin-bottom: -8px; }

.p-10 {
  padding: 10px; }

.p-t-10 {
  padding-top: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.m-10 {
  margin: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-l-n-10 {
  margin-left: -10px; }

.m-t-10 {
  margin-top: 10px; }

.m-t-n-10 {
  margin-top: -10px; }

.m-r-10 {
  margin-right: 10px; }

.m-r-n-10 {
  margin-right: -10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-b-n-10 {
  margin-bottom: -10px; }

.p-12 {
  padding: 12px; }

.p-t-12 {
  padding-top: 12px; }

.p-l-12 {
  padding-left: 12px; }

.p-r-12 {
  padding-right: 12px; }

.p-b-12 {
  padding-bottom: 12px; }

.m-12 {
  margin: 12px; }

.m-l-12 {
  margin-left: 12px; }

.m-l-n-12 {
  margin-left: -12px; }

.m-t-12 {
  margin-top: 12px; }

.m-t-n-12 {
  margin-top: -12px; }

.m-r-12 {
  margin-right: 12px; }

.m-r-n-12 {
  margin-right: -12px; }

.m-b-12 {
  margin-bottom: 12px; }

.m-b-n-12 {
  margin-bottom: -12px; }

.p-15 {
  padding: 15px; }

.p-t-15 {
  padding-top: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.m-15 {
  margin: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-l-n-15 {
  margin-left: -15px; }

.m-t-15 {
  margin-top: 15px; }

.m-t-n-15 {
  margin-top: -15px; }

.m-r-15 {
  margin-right: 15px; }

.m-r-n-15 {
  margin-right: -15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-b-n-15 {
  margin-bottom: -15px; }

.p-16 {
  padding: 16px; }

.p-t-16 {
  padding-top: 16px; }

.p-l-16 {
  padding-left: 16px; }

.p-r-16 {
  padding-right: 16px; }

.p-b-16 {
  padding-bottom: 16px; }

.m-16 {
  margin: 16px; }

.m-l-16 {
  margin-left: 16px; }

.m-l-n-16 {
  margin-left: -16px; }

.m-t-16 {
  margin-top: 16px; }

.m-t-n-16 {
  margin-top: -16px; }

.m-r-16 {
  margin-right: 16px; }

.m-r-n-16 {
  margin-right: -16px; }

.m-b-16 {
  margin-bottom: 16px; }

.m-b-n-16 {
  margin-bottom: -16px; }

.p-20 {
  padding: 20px; }

.p-t-20 {
  padding-top: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.m-20 {
  margin: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-l-n-20 {
  margin-left: -20px; }

.m-t-20 {
  margin-top: 20px; }

.m-t-n-20 {
  margin-top: -20px; }

.m-r-20 {
  margin-right: 20px; }

.m-r-n-20 {
  margin-right: -20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-b-n-20 {
  margin-bottom: -20px; }

.p-22 {
  padding: 22px; }

.p-t-22 {
  padding-top: 22px; }

.p-l-22 {
  padding-left: 22px; }

.p-r-22 {
  padding-right: 22px; }

.p-b-22 {
  padding-bottom: 22px; }

.m-22 {
  margin: 22px; }

.m-l-22 {
  margin-left: 22px; }

.m-l-n-22 {
  margin-left: -22px; }

.m-t-22 {
  margin-top: 22px; }

.m-t-n-22 {
  margin-top: -22px; }

.m-r-22 {
  margin-right: 22px; }

.m-r-n-22 {
  margin-right: -22px; }

.m-b-22 {
  margin-bottom: 22px; }

.m-b-n-22 {
  margin-bottom: -22px; }

.p-24 {
  padding: 24px; }

.p-t-24 {
  padding-top: 24px; }

.p-l-24 {
  padding-left: 24px; }

.p-r-24 {
  padding-right: 24px; }

.p-b-24 {
  padding-bottom: 24px; }

.m-24 {
  margin: 24px; }

.m-l-24 {
  margin-left: 24px; }

.m-l-n-24 {
  margin-left: -24px; }

.m-t-24 {
  margin-top: 24px; }

.m-t-n-24 {
  margin-top: -24px; }

.m-r-24 {
  margin-right: 24px; }

.m-r-n-24 {
  margin-right: -24px; }

.m-b-24 {
  margin-bottom: 24px; }

.m-b-n-24 {
  margin-bottom: -24px; }

.p-25 {
  padding: 25px; }

.p-t-25 {
  padding-top: 25px; }

.p-l-25 {
  padding-left: 25px; }

.p-r-25 {
  padding-right: 25px; }

.p-b-25 {
  padding-bottom: 25px; }

.m-25 {
  margin: 25px; }

.m-l-25 {
  margin-left: 25px; }

.m-l-n-25 {
  margin-left: -25px; }

.m-t-25 {
  margin-top: 25px; }

.m-t-n-25 {
  margin-top: -25px; }

.m-r-25 {
  margin-right: 25px; }

.m-r-n-25 {
  margin-right: -25px; }

.m-b-25 {
  margin-bottom: 25px; }

.m-b-n-25 {
  margin-bottom: -25px; }

.p-30 {
  padding: 30px; }

.p-t-30 {
  padding-top: 30px; }

.p-l-30 {
  padding-left: 30px; }

.p-r-30 {
  padding-right: 30px; }

.p-b-30 {
  padding-bottom: 30px; }

.m-30 {
  margin: 30px; }

.m-l-30 {
  margin-left: 30px; }

.m-l-n-30 {
  margin-left: -30px; }

.m-t-30 {
  margin-top: 30px; }

.m-t-n-30 {
  margin-top: -30px; }

.m-r-30 {
  margin-right: 30px; }

.m-r-n-30 {
  margin-right: -30px; }

.m-b-30 {
  margin-bottom: 30px; }

.m-b-n-30 {
  margin-bottom: -30px; }

.p-32 {
  padding: 32px; }

.p-t-32 {
  padding-top: 32px; }

.p-l-32 {
  padding-left: 32px; }

.p-r-32 {
  padding-right: 32px; }

.p-b-32 {
  padding-bottom: 32px; }

.m-32 {
  margin: 32px; }

.m-l-32 {
  margin-left: 32px; }

.m-l-n-32 {
  margin-left: -32px; }

.m-t-32 {
  margin-top: 32px; }

.m-t-n-32 {
  margin-top: -32px; }

.m-r-32 {
  margin-right: 32px; }

.m-r-n-32 {
  margin-right: -32px; }

.m-b-32 {
  margin-bottom: 32px; }

.m-b-n-32 {
  margin-bottom: -32px; }

.p-40 {
  padding: 40px; }

.p-t-40 {
  padding-top: 40px; }

.p-l-40 {
  padding-left: 40px; }

.p-r-40 {
  padding-right: 40px; }

.p-b-40 {
  padding-bottom: 40px; }

.m-40 {
  margin: 40px; }

.m-l-40 {
  margin-left: 40px; }

.m-l-n-40 {
  margin-left: -40px; }

.m-t-40 {
  margin-top: 40px; }

.m-t-n-40 {
  margin-top: -40px; }

.m-r-40 {
  margin-right: 40px; }

.m-r-n-40 {
  margin-right: -40px; }

.m-b-40 {
  margin-bottom: 40px; }

.m-b-n-40 {
  margin-bottom: -40px; }

.p-44 {
  padding: 44px; }

.p-t-44 {
  padding-top: 44px; }

.p-l-44 {
  padding-left: 44px; }

.p-r-44 {
  padding-right: 44px; }

.p-b-44 {
  padding-bottom: 44px; }

.m-44 {
  margin: 44px; }

.m-l-44 {
  margin-left: 44px; }

.m-l-n-44 {
  margin-left: -44px; }

.m-t-44 {
  margin-top: 44px; }

.m-t-n-44 {
  margin-top: -44px; }

.m-r-44 {
  margin-right: 44px; }

.m-r-n-44 {
  margin-right: -44px; }

.m-b-44 {
  margin-bottom: 44px; }

.m-b-n-44 {
  margin-bottom: -44px; }

.p-50 {
  padding: 50px; }

.p-t-50 {
  padding-top: 50px; }

.p-l-50 {
  padding-left: 50px; }

.p-r-50 {
  padding-right: 50px; }

.p-b-50 {
  padding-bottom: 50px; }

.m-50 {
  margin: 50px; }

.m-l-50 {
  margin-left: 50px; }

.m-l-n-50 {
  margin-left: -50px; }

.m-t-50 {
  margin-top: 50px; }

.m-t-n-50 {
  margin-top: -50px; }

.m-r-50 {
  margin-right: 50px; }

.m-r-n-50 {
  margin-right: -50px; }

.m-b-50 {
  margin-bottom: 50px; }

.m-b-n-50 {
  margin-bottom: -50px; }

.p-52 {
  padding: 52px; }

.p-t-52 {
  padding-top: 52px; }

.p-l-52 {
  padding-left: 52px; }

.p-r-52 {
  padding-right: 52px; }

.p-b-52 {
  padding-bottom: 52px; }

.m-52 {
  margin: 52px; }

.m-l-52 {
  margin-left: 52px; }

.m-l-n-52 {
  margin-left: -52px; }

.m-t-52 {
  margin-top: 52px; }

.m-t-n-52 {
  margin-top: -52px; }

.m-r-52 {
  margin-right: 52px; }

.m-r-n-52 {
  margin-right: -52px; }

.m-b-52 {
  margin-bottom: 52px; }

.m-b-n-52 {
  margin-bottom: -52px; }

.p-60 {
  padding: 60px; }

.p-t-60 {
  padding-top: 60px; }

.p-l-60 {
  padding-left: 60px; }

.p-r-60 {
  padding-right: 60px; }

.p-b-60 {
  padding-bottom: 60px; }

.m-60 {
  margin: 60px; }

.m-l-60 {
  margin-left: 60px; }

.m-l-n-60 {
  margin-left: -60px; }

.m-t-60 {
  margin-top: 60px; }

.m-t-n-60 {
  margin-top: -60px; }

.m-r-60 {
  margin-right: 60px; }

.m-r-n-60 {
  margin-right: -60px; }

.m-b-60 {
  margin-bottom: 60px; }

.m-b-n-60 {
  margin-bottom: -60px; }

.p-80 {
  padding: 80px; }

.p-t-80 {
  padding-top: 80px; }

.p-l-80 {
  padding-left: 80px; }

.p-r-80 {
  padding-right: 80px; }

.p-b-80 {
  padding-bottom: 80px; }

.m-80 {
  margin: 80px; }

.m-l-80 {
  margin-left: 80px; }

.m-l-n-80 {
  margin-left: -80px; }

.m-t-80 {
  margin-top: 80px; }

.m-t-n-80 {
  margin-top: -80px; }

.m-r-80 {
  margin-right: 80px; }

.m-r-n-80 {
  margin-right: -80px; }

.m-b-80 {
  margin-bottom: 80px; }

.m-b-n-80 {
  margin-bottom: -80px; }

.inline {
  display: inline-block; }

.inline-only {
  display: inline; }

.table-cell {
  display: table-cell; }

/*
* PROGRESS BAR
*/
.progress-container > div {
  margin-bottom: 10px; }

.progress-container .progress {
  background-color: #DBDBDB;
  height: 2px; }
  .progress-container .progress.md {
    height: 4px; }

/*
* BACKGROUND COLOR
*/
.bg-success {
  background-color: #7C9A76; }

.bg-danger {
  background-color: #EA4D4D; }

.bg-info {
  background-color: #2F80ED; }

.bg-header-gray {
  background-color: #F1F1F1; }

.bg-body {
  background-color: #F2F2F2; }

.bg-white {
  background-color: #FFFFFF; }

.bg-light-blue {
  background-color: #BDE6EC; }

.bg-purple {
  background-color: #564FDB; }

.bg-dark {
  background-color: #333333; }

.success {
  background-color: #7C9A76; }

.danger {
  background-color: #E36B6B; }

.info {
  background-color: #6472C7; }

.box-shadow {
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.19), 1px 8px 20px 4px rgba(169, 165, 165, 0.12); }

.line-height-sm {
  line-height: 1.2; }

.line-height-md {
  line-height: 1.4; }

.line-height-l {
  line-height: 2.4; }

.line-height-1 {
  line-height: 1; }

.full-width {
  width: 100%; }

.max-width {
  max-width: 100%; }

.bg-color-transparent {
  background-color: transparent; }

.input-thankyou {
  width: 100%;
  height: 35px;
  border-radius: 3.5px;
  border: 1px solid #333333;
  padding: 5px 10px;
  font-size: 16px; }

.input-thankyou::-webkit-input-placeholder {
  color: #B7B7B7 !important; }

/* WebKit, Blink, Edge */
.input-thankyou:-moz-placeholder {
  color: #B7B7B7 !important; }

/* Mozilla Firefox 4 to 18 */
.input-thankyou::-moz-placeholder {
  color: #B7B7B7 !important; }

/* Mozilla Firefox 19+ */
.input-thankyou:-ms-input-placeholder {
  color: #B7B7B7 !important; }

/* Internet Explorer 10-11 */
.input-thankyou::-ms-input-placeholder {
  color: #B7B7B7 !important; }

/* Microsoft Edge */
.input-thankyou-link {
  width: 86%;
  font-size: 13px;
  height: 35px;
  font-weight: bold;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #333333;
  padding: 5px 10px; }

.div-thankyou {
  padding-bottom: 10px; }

.button-save {
  background-color: #564fdb;
  border: 1px solid #564fdb;
  border-radius: 5px;
  color: #fff;
  min-width: 106px;
  height: 37px;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 20px; }

.button-copy {
  background-color: #564fdb;
  border: 1px solid #564fdb;
  border-radius: 0px 5px 5px 0px;
  color: #fff;
  width: 100px;
  min-width: 60px;
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  vertical-align: top; }

.form-thankyou {
  display: contents; }

.input-error {
  border-color: #EA4D4D;
  color: #EA4D4D; }

.profilelink {
  font-size: 15px;
  color: #333333; }

.enlarge_image {
  margin-top: -1px !important;
  min-width: 282% !important; }

.wrapper__intro {
  padding: 150px 10px;
  text-align: center; }
  .wrapper__intro .intro-body {
    margin-bottom: 40px; }
    .wrapper__intro .intro-body h3 {
      font-size: 24px;
      color: #716FB7;
      margin: 0;
      line-height: 32px; }
  .wrapper__intro .intro-footer {
    width: 260px;
    margin: 0 auto; }
    .wrapper__intro .intro-footer a {
      margin: 0 5px; }
  .wrapper__intro.warning, .wrapper__intro.info {
    color: #7A7A7A; }
    .wrapper__intro.warning .intro-body, .wrapper__intro.info .intro-body {
      max-width: 1000px;
      margin: 0 auto;
      text-align: left; }
      .wrapper__intro.warning .intro-body ol, .wrapper__intro.info .intro-body ol {
        padding-left: 20px;
        padding-top: 20px; }
        .wrapper__intro.warning .intro-body ol li, .wrapper__intro.info .intro-body ol li {
          padding-bottom: 15px; }
      .wrapper__intro.warning .intro-body i, .wrapper__intro.info .intro-body i {
        margin-right: 40px; }
      .wrapper__intro.warning .intro-body a, .wrapper__intro.info .intro-body a {
        text-decoration: underline; }
      .wrapper__intro.warning .intro-body p, .wrapper__intro.info .intro-body p {
        color: #828282; }
  .wrapper__intro.warning .intro-body h3 {
    color: #DE5567;
    font-weight: 600; }
  .wrapper__intro.info .intro-body h3 {
    color: #828282;
    font-weight: 600; }

.interview__header .interview__subheader {
  width: 365px;
  margin: 0 auto; }
  .interview__header .interview__subheader > h5 {
    font-size: 16px;
    font-weight: 600;
    color: #3F52C7;
    padding-bottom: 20px; }
  .interview__header .interview__subheader .interview_summary {
    background-color: #F4F4F4;
    padding: 32px 25px;
    border-radius: 8px;
    margin: 0 auto 80px;
    border: 1px solid #D3D3D3; }
    .interview__header .interview__subheader .interview_summary .summary--desc i {
      min-height: 50px; }
    .interview__header .interview__subheader .interview_summary .summary--desc small {
      display: block;
      font-size: 16px;
      color: #132ED5; }
  .interview__header .interview__subheader .interview_action {
    padding-bottom: 20px; }
  .interview__header .interview__subheader .error {
    margin-top: 40px;
    color: #DF5666;
    font-size: 14px;
    font-weight: 600; }

.interview__header.interview--finish {
  border-bottom: 0;
  background-image: url(../../images/bg-finish-page.jpg);
  background-size: cover;
  background-color: #212342;
  min-height: calc(100vh - 74px); }
  .interview__header.interview--finish .header-title {
    padding: 40px 15px; }
    .interview__header.interview--finish .header-title h2 {
      font-size: 30px;
      word-wrap: break-word;
      color: #FF9D00;
      line-height: 30px; }
  .interview__header.interview--finish .finish--title {
    margin-bottom: 20px; }
    .interview__header.interview--finish .finish--title h2 {
      font-size: 20px;
      color: #FFFFFF;
      line-height: 30px; }
  .interview__header.interview--finish .finish--image {
    margin: 70px 0; }
  .interview__header.interview--finish .finish--btn {
    max-width: 600px;
    display: inline-block;
    margin: 0 auto;
    padding-bottom: 20px; }
    .interview__header.interview--finish .finish--btn .btn {
      color: #FFFFFF;
      text-decoration: underline; }

.interview__header .interview__error, .interview__header .interview__loading_camera {
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  color: #676767;
  text-align: center; }

.interview__time {
  border-bottom: 1px solid #D8D8D8;
  padding: 20px; }
  .interview__time .interview-length i {
    margin-bottom: 15px; }
  .interview__time .interview-length .desc {
    color: #3E3C82;
    text-align: center; }
    .interview__time .interview-length .desc small {
      font-size: 16px; }

/*
* CUSTOM OVERLAY
*/
.wrapper-overlay .overlay-content {
  color: #FFFFFF;
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  height: 60vh; }
  .wrapper-overlay .overlay-content h1 {
    font-size: 22px;
    font-weight: bold; }
  .wrapper-overlay .overlay-content a {
    color: #FFFFFF; }
  .wrapper-overlay .overlay-content p {
    font-size: 15px; }

/*
* 404 PAGE
*/
body.error-page {
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  font-size: 25px;
  padding: 0;
  margin: 0; }
  body.error-page .error {
    display: table;
    width: 100%;
    height: 100%;
    padding: 10px 0 20px 0;
    text-align: center;
    color: #fff;
    background: #333333 url(../../images/stars.png) no-repeat bottom center scroll;
    background-size: cover;
    -o-background-size: cover;
    overflow: hidden; }
  body.error-page .error-body {
    display: table-cell;
    vertical-align: middle; }
  body.error-page .error-content {
    position: relative; }
    body.error-page .error-content .link {
      font-size: 17px;
      margin-top: 80px;
      position: relative; }
      body.error-page .error-content .link a:hover {
        text-decoration: underline; }
    body.error-page .error-content .text, body.error-page .error-content a {
      color: #FFFFFF;
      text-align: center;
      text-decoration: none; }
    body.error-page .error-content .text p {
      font-size: 19px;
      margin: 10px 0 5px; }
    body.error-page .error-content .img-notfound {
      background: transparent url(../../images/404.png) no-repeat top center;
      position: relative;
      width: 379px;
      height: 221px;
      margin: 0 auto; }

/*
* INTERVIEW VIDEO
*/
.wrapper-fullscreen {
  overflow: hidden;
  height: 100vh;
  background-color: #FFFFFF; }
  .wrapper-fullscreen .header-video {
    color: #FFFFFF;
    min-height: 80px;
    position: relative;
    padding: 12px 24px; }
    .wrapper-fullscreen .header-video .heading6 {
      margin-bottom: 8px; }
    .wrapper-fullscreen .header-video .header-volume {
      position: absolute;
      right: 20px;
      bottom: 0; }
    .wrapper-fullscreen .header-video__title h1 {
      text-transform: uppercase;
      font-size: 18px;
      margin-top: 0; }
    .wrapper-fullscreen .header-video__question p {
      font-size: 18px;
      margin: 0; }
    .wrapper-fullscreen .header-video__question .dots {
      -webkit-animation: pulse 1.5s linear infinite;
      -moz-animation: pulse 1.5s linear infinite;
      border-image: initial; }
      .wrapper-fullscreen .header-video__question .dots.red:before {
        content: ' \25CF';
        font-size: 25px;
        color: #D95F5F;
        display: inline-block; }
      .wrapper-fullscreen .header-video__question .dots.grey:before {
        content: ' \25CF';
        font-size: 25px;
        color: #C3C3C3;
        display: inline-block; }
  .wrapper-fullscreen .body-video {
    position: relative; }
  .wrapper-fullscreen .footer-video {
    background-color: rgba(51, 51, 51, 0.8);
    min-height: 80px;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 0.5rem; }

.video-player {
  width: 100%;
  height: calc(100vh); }

video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1); }

.video-welcome {
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

.video-control .button {
  margin: 0 24px; }

audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline,
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-fullscreen-button {
  display: none; }

.audio-playback-container {
  width: 100%;
  height: auto;
  background-color: transparent;
  border-radius: 5px;
  margin: 0 auto;
  color: #333333;
  padding: 0 10px;
  justify-content: center; }
  .audio-playback-container .fa-play {
    font-size: 32px; }
  .audio-playback-container i {
    font-size: 12px; }
  .audio-playback-container p {
    font-size: 18px;
    font-weight: normal; }
  .audio-playback-container span {
    padding: 0 10px;
    font-size: 18px;
    font-weight: bold; }
    .audio-playback-container span i {
      margin-right: 10px; }
  .audio-playback-container .div-timeline-audio {
    width: 285px;
    height: 5px;
    background-color: #3E3E3E; }
    .audio-playback-container .div-timeline-audio .div-timeline-audio-progress {
      height: 5px;
      background-color: #5A5A5A;
      border-radius: 10px; }

.videoModal {
  width: 100%;
  max-height: 83vh; }

.playLeft {
  margin-left: 65%; }

.hide {
  display: none; }

/*
* SECTIONS
*/
.wrapper-fixed-footer {
  background-color: #3E3C82;
  padding: 8px 0;
  margin: 0 auto;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1; }
  .wrapper-fixed-footer .navigation-footer {
    width: 100%; }

/*
* CUSTOM MODAL
*/
.welcome-video-modal .content {
  position: relative;
  display: inline-block; }

.welcome-video-modal .content > .custom-button {
  position: absolute;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  height: 28px;
  z-index: 10000;
  bottom: 25px;
  margin-left: -87%; }

.modal .modal-header {
  border-bottom: 0; }

.modal .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: 0; }

.modal button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0; }

.modal .close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2; }

.welcome-video-modal {
  background-color: transparent;
  max-width: 850px !important;
  max-height: 100% !important; }
  @media screen and (max-width: 1500px) {
    .welcome-video-modal {
      max-width: 850px !important; } }
  @media screen and (max-width: 1024px) {
    .welcome-video-modal {
      max-width: 640px !important; } }
  @media screen and (max-height: 768px) {
    .welcome-video-modal {
      max-height: 640px !important; } }
  .welcome-video-modal .content {
    max-height: 100%;
    padding: 15px !important; }
  .welcome-video-modal .modal-footer {
    border-top: 0; }
  .welcome-video-modal .close-button {
    background-color: white;
    width: 60px;
    height: 40px;
    align-items: center;
    align-content: center;
    text-align: center;
    margin: 0 auto; }

.img-option {
  max-width: 300px;
  max-height: 240px;
  cursor: zoom-in; }

.img-question {
  max-width: 500px;
  max-height: 240px;
  cursor: zoom-in; }

/*
* CUSTOM RESULT
*/
audio::-webkit-media-controls-timeline, video::-webkit-media-controls-timeline {
  display: none; }

.wrapper-failed {
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 9;
  position: absolute;
  width: 100%; }
  .wrapper-failed .failed-content {
    position: relative;
    width: 100%;
    text-align: center;
    height: 100vh;
    padding-top: 8%; }
  .wrapper-failed .unsupported-content {
    position: relative;
    width: 100%;
    text-align: center;
    height: 88vh;
    padding-top: 8%; }
  .wrapper-failed .grey-color-font {
    color: #828282; }
  .wrapper-failed h2 {
    color: #ec5457;
    margin-bottom: 50px; }
  .wrapper-failed p a {
    text-decoration: underline; }

.sidebar {
  margin-right: 0; }

.main-wrapper {
  position: relative; }

@media screen and (max-width: 768px) {
  .img-header-unsupported {
    height: 40px; }
  .wrapper-failed h1 {
    font-size: 25px; }
  .wrapper-failed h6 {
    font-size: 15px; } }

.video-playback-container {
  width: 100%;
  height: 37px;
  background-color: #000000;
  border-radius: 0 0 4px 4px;
  margin: 0 auto;
  color: white;
  padding: 0 10px;
  justify-content: center; }
  .video-playback-container i {
    font-size: 12px;
    cursor: pointer; }
  .video-playback-container span {
    padding: 0 10px;
    font-size: 12px;
    font-weight: bold; }
  .video-playback-container .div-timeline-audio {
    width: 67%;
    height: 5px;
    background-color: #3E3E3E;
    position: relative; }
    .video-playback-container .div-timeline-audio .div-timeline-audio-progress {
      width: 30%;
      height: 5px;
      background-color: #5A5A5A;
      border-radius: 10px; }
    .video-playback-container .div-timeline-audio .red-timeline {
      position: absolute;
      background: #FFF;
      height: 5px;
      width: 75%; }
  .video-playback-container input[type="range"] {
    position: absolute;
    height: 3px;
    width: 100%;
    cursor: pointer;
    background-color: #414144;
    -webkit-appearance: none !important; }
  .video-playback-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    position: relative;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #FFFFFF;
    transition: 0.7s ease-in-out forwards;
    cursor: pointer;
    opacity: 0; }
  .video-playback-container input[type="range"]:hover::-webkit-slider-thumb {
    opacity: 1; }

.video-question-container {
  /* Could Use thise as well for Individual Controls */
  border-radius: 4px 4px 0 0;
  position: relative; }
  .video-question-container video::-webkit-media-controls {
    display: none; }
  .video-question-container .video-question {
    width: 100%;
    max-height: 432px;
    outline: none;
    border-radius: 4px 4px 0 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    background-color: black; }

.p-video-control {
  margin-top: -6px; }

.rating-container {
  width: 100%;
  transform: scaleX(-1); }
  .rating-container button:hover ~ button, .rating-container button:focus ~ button, .rating-container button:active ~ button, .rating-container .active ~ button {
    background-color: #564FDB;
    color: white; }

.rating-container-mobile {
  width: 100%;
  transform: scaleX(-1); }
  .rating-container-mobile button:hover ~ button, .rating-container-mobile button:focus ~ button, .rating-container-mobile button:active ~ button, .rating-container-mobile .active ~ button {
    background-color: #564FDB;
    color: white; }

.textarea-rating {
  width: 100%;
  height: 140px;
  resize: none;
  border: thin solid #999999;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
  color: #666666; }

.container-teacher {
  margin-top: 15px; }
  .container-teacher .pengajar-container {
    display: flex; }
    .container-teacher .pengajar-container img {
      margin: 0;
      width: 120px;
      height: 120px;
      min-width: 120px; }
    .container-teacher .pengajar-container .pengajar-detail {
      padding-left: 20px; }
      .container-teacher .pengajar-container .pengajar-detail h4, .container-teacher .pengajar-container .pengajar-detail p, .container-teacher .pengajar-container .pengajar-detail label {
        color: #2C2C2C; }
      .container-teacher .pengajar-container .pengajar-detail h4 {
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: bold; }
      .container-teacher .pengajar-container .pengajar-detail label {
        margin-bottom: 8px;
        opacity: 0.5;
        font-size: 14px;
        font-weight: bold; }
      .container-teacher .pengajar-container .pengajar-detail p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 18px; }
  .container-teacher .pengajar-container:not(:last-child) {
    margin-bottom: 30px; }

.wrapper-request-ecourse {
  padding: 42px 50px;
  background-color: white;
  border-radius: 9px;
  box-shadow: 0px 0px 10px #00000019;
  margin-bottom: 20px;
  text-align: center; }
  .wrapper-request-ecourse button {
    border-radius: 4px;
    width: 100%;
    padding: 15px 0;
    font-weight: bold;
    font-size: 16px;
    box-shadow: 0px 5px 10px #66666626; }
  .wrapper-request-ecourse label {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    margin: 35px 0 27px; }
  .wrapper-request-ecourse p {
    color: #707070;
    font-size: 16px;
    line-height: 24px; }

/* ----------- Non-Retina Screens ----------- */
@media (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

@media screen and (min-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

@media screen and (min-device-width: 960px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

@media screen and (min-device-width: 960px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 960px) and (max-width: 1279px) {
  .container {
    max-width: 960px;
    padding: 0; } }

/* ----------- Tablet ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 720px) and (max-device-width: 960px) and (-webkit-min-device-pixel-ratio: 1) and (min-width: 720px) and (max-width: 959px) {
  .container {
    max-width: 720px;
    padding: 0; }
  #sidebar {
    position: relative; } }

/* ----------- iPhone 6+ ----------- */
/* Portrait and Landscape */
/* ----------- iPhone under 5 ----------- */
/* Portrait and Landscape */
@media screen and (min-height: 1px) and (max-height: 750px) and (orientation: landscape) {
  .m-t-small {
    margin-top: 10px !important; }
  .m-b-small {
    margin-bottom: 10px !important; }
  .m-t-smaller {
    margin-top: 5px !important; }
  .m-b-smaller {
    margin-bottom: 5px !important; } }

@media screen and (min-height: 701px) and (max-height: 750px) and (orientation: landscape) {
  .m-t-smaller {
    margin-top: 25px !important; }
  .m-b-smaller {
    margin-bottom: 25px !important; } }

/* Custom Responsive Style Wildan */
.header-desktop-hide {
  display: flex; }

.header-mobile-hide {
  display: none; }

.card-desktop-hide {
  display: block; }

.card-mobile-hide {
  display: none; }

.div-rating-desktop-hide {
  display: inline-block !important; }

.div-rating-mobile-hide {
  display: none !important; }

@media screen and (max-width: 767px) {
  .navbar-brand {
    max-width: 100px;
    max-height: 40px;
    margin-right: 0; }
  .header-desktop-hide {
    display: none; }
  .header-mobile-hide {
    display: flex; }
  .card-desktop-hide {
    display: none; }
  .card-mobile-hide {
    display: block; }
  .card .heading2, .card .card-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px; }
  .card .heading3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px; }
  .card .heading4, .card .card-text {
    font-size: 14px; }
  .card .div-topic-list {
    font-size: 14px; }
    .card .div-topic-list .fa-caret-right {
      display: none; }
  .div-rating-list {
    width: 100% !important; }
  .div-lihat-semua {
    display: block !important;
    text-align: right; }
  .rupiah {
    font-size: 16px !important; }
  .btn-start-session {
    font-size: 14px;
    padding: 10px 35px; }
  .play-button-welcome {
    width: 24px; }
  .width-info {
    max-width: 78%; }
  .width-dropdown {
    width: 22%; }
  .card-welcome {
    padding: 0 15px !important; }
  .div-rating-desktop-hide {
    display: none !important; }
  .div-rating-mobile-hide {
    display: flex !important; }
  .header-info-logout {
    margin-left: auto; }
  .login {
    padding: 20px 10px;
    overflow: hidden; }
  .registration {
    padding: 20px 10px;
    overflow: hidden; }
  .div-apply-now {
    margin-left: auto;
    margin-right: auto; }
  .div-apply-now hr {
    width: 100%; }
  .div-apply-now p {
    font-size: 18px !important;
    margin-left: auto !important; }
  .course-reset-text {
    font-size: 16px !important; }
  .button-play-video {
    font-size: 26px;
    width: 60px;
    height: 60px; }
  .video-playback-container .div-timeline-audio {
    width: 50%; }
  .card__rating .row-textarea {
    margin-left: 0px;
    margin-right: 0px; }
  .card {
    padding: 10px 15px; }
  .div-vid-ins {
    width: 100%; }
  .wrapper-fixed-footer .heading4, .wrapper-fixed-footer .card .card-text, .card .wrapper-fixed-footer .card-text {
    font-size: 12px;
    line-height: 13px; }
  .wrapper-fixed-footer .container {
    padding-left: 0;
    padding-right: 0; }
  .wrapper-fixed-footer .m-r-12 {
    margin-right: 5px; }
  .wrapper-fixed-footer .m-l-12 {
    margin-left: 5px; }
  .button__text-only {
    font-size: 12px; }
  .btn-swal-pembayaran {
    font-size: 17px; } }

@media screen and (max-width: 375px) and (orientation: portrait) {
  .video-playback-container .div-timeline-audio {
    width: 45%; }
  .div-vid-ins .video-playback-container .div-timeline-audio {
    width: 43%; }
  .btn-swal-pembayaran {
    font-size: 16px; } }

@media screen and (max-width: 360px) and (orientation: portrait) {
  .video-playback-container .div-timeline-audio {
    width: 42%; }
  .div-vid-ins .video-playback-container .div-timeline-audio {
    width: 40%; }
  .btn-swal-pembayaran {
    font-size: 14px; } }

@media screen and (max-width: 320px) and (orientation: portrait) {
  .card {
    padding: 10px 15px; }
  .icon-play-purple {
    margin-right: 10px; }
  .video-playback-container .div-timeline-audio {
    width: 35%; }
  .div-vid-ins .video-playback-container .div-timeline-audio {
    width: 32%; }
  .btn-swal-pembayaran {
    font-size: 13px; } }

@media screen and (max-width: 767px) and (orientation: portrait) {
  #video-full-container:fullscreen .button-play-video {
    top: 18%; } }

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/
