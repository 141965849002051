/* ----------- Non-Retina Screens ----------- */
@media (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

@media screen and (min-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

@media screen and (min-device-width: 960px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 1280px) {
  .container {
    max-width: 800px;
    padding: 0; } }

@media screen and (min-device-width: 960px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (min-width: 960px) and (max-width: 1279px) {
  .container {
    max-width: 960px;
    padding: 0; } }

/* ----------- Tablet ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 720px) and (max-device-width: 960px) and (-webkit-min-device-pixel-ratio: 1) and (min-width: 720px) and (max-width: 959px) {
  .container {
    max-width: 720px;
    padding: 0; }
  #sidebar {
    position: relative; } }

/* ----------- iPhone 6+ ----------- */
/* Portrait and Landscape */
/* ----------- iPhone under 5 ----------- */
/* Portrait and Landscape */
@media screen and (min-height: 1px) and (max-height: 750px) and (orientation: landscape) {
  .m-t-small {
    margin-top: 10px !important; }
  .m-b-small {
    margin-bottom: 10px !important; }
  .m-t-smaller {
    margin-top: 5px !important; }
  .m-b-smaller {
    margin-bottom: 5px !important; } }

@media screen and (min-height: 701px) and (max-height: 750px) and (orientation: landscape) {
  .m-t-smaller {
    margin-top: 25px !important; }
  .m-b-smaller {
    margin-bottom: 25px !important; } }

/* Custom Responsive Style Wildan */
.header-desktop-hide {
  display: flex; }

.header-mobile-hide {
  display: none; }

.card-desktop-hide {
  display: block; }

.card-mobile-hide {
  display: none; }

.div-rating-desktop-hide {
  display: inline-block !important; }

.div-rating-mobile-hide {
  display: none !important; }

@media screen and (max-width: 767px) {
  .navbar-brand {
    max-width: 100px;
    max-height: 40px;
    margin-right: 0; }
  .header-desktop-hide {
    display: none; }
  .header-mobile-hide {
    display: flex; }
  .card-desktop-hide {
    display: none; }
  .card-mobile-hide {
    display: block; }
  .card .heading2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px; }
  .card .heading3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px; }
  .card .heading4 {
    font-size: 14px; }
  .card .div-topic-list {
    font-size: 14px; }
    .card .div-topic-list .fa-caret-right {
      display: none; }
  .div-rating-list {
    width: 100% !important; }
  .div-lihat-semua {
    display: block !important;
    text-align: right; }
  .rupiah {
    font-size: 16px !important; }
  .btn-start-session {
    font-size: 14px;
    padding: 10px 35px; }
  .play-button-welcome {
    width: 24px; }
  .width-info {
    max-width: 78%; }
  .width-dropdown {
    width: 22%; }
  .card-welcome {
    padding: 0 15px !important; }
  .div-rating-desktop-hide {
    display: none !important; }
  .div-rating-mobile-hide {
    display: flex !important; }
  .header-info-logout {
    margin-left: auto; }
  .login {
    padding: 20px 10px;
    overflow: hidden; }
  .registration {
    padding: 20px 10px;
    overflow: hidden; }
  .div-apply-now {
    margin-left: auto;
    margin-right: auto; }
  .div-apply-now hr {
    width: 100%; }
  .div-apply-now p {
    font-size: 18px !important;
    margin-left: auto !important; }
  .course-reset-text {
    font-size: 16px !important; }
  .button-play-video {
    font-size: 26px;
    width: 60px;
    height: 60px; }
  .video-playback-container .div-timeline-audio {
    width: 50%; }
  .card__rating .row-textarea {
    margin-left: 0px;
    margin-right: 0px; }
  .card {
    padding: 10px 15px; }
  .div-vid-ins {
    width: 100%; }
  .wrapper-fixed-footer .heading4 {
    font-size: 12px;
    line-height: 13px; }
  .wrapper-fixed-footer .container {
    padding-left: 0;
    padding-right: 0; }
  .wrapper-fixed-footer .m-r-12 {
    margin-right: 5px; }
  .wrapper-fixed-footer .m-l-12 {
    margin-left: 5px; }
  .button__text-only {
    font-size: 12px; }
  .btn-swal-pembayaran {
    font-size: 17px; } }

@media screen and (max-width: 375px) and (orientation: portrait) {
  .video-playback-container .div-timeline-audio {
    width: 45%; }
  .div-vid-ins .video-playback-container .div-timeline-audio {
    width: 43%; }
  .btn-swal-pembayaran {
    font-size: 16px; } }

@media screen and (max-width: 360px) and (orientation: portrait) {
  .video-playback-container .div-timeline-audio {
    width: 42%; }
  .div-vid-ins .video-playback-container .div-timeline-audio {
    width: 40%; }
  .btn-swal-pembayaran {
    font-size: 14px; } }

@media screen and (max-width: 320px) and (orientation: portrait) {
  .card {
    padding: 10px 15px; }
  .icon-play-purple {
    margin-right: 10px; }
  .video-playback-container .div-timeline-audio {
    width: 35%; }
  .div-vid-ins .video-playback-container .div-timeline-audio {
    width: 32%; }
  .btn-swal-pembayaran {
    font-size: 13px; } }

@media screen and (max-width: 767px) and (orientation: portrait) {
  #video-full-container:fullscreen .button-play-video {
    top: 18%; } }

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/
